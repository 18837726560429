import { INameValue } from "../types/System";

export const numberOFSamplesOptions: INameValue[] = [
  {
    name: "Select",
    value: "",
  },
  {
    name: "10 (the smallest number of samples to verify the workflow)",
    value: "10",
  },
  {
    name: "2500 (a small number of samples just for basic testing)",
    value: "2500",
  },
  {
    name: "10,000 (a good balance between speed and accuracy)",
    value: "10000",
  },
  {
    name: "25,000 (minimum for acceptable accuracy)",
    value: "25000",
  },
  {
    name: "50,000+ (minimum for robust use cases)",
    value: "50000",
  },
];
