import { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { HomeContext } from "../contexts/HomeContext";
import { ReactComponent as ArrowRight } from "../images/arrow-right.svg";
import "./Sidebar.scss";

const Sidebar = () => {
  const [expand, setExpand] = useState<boolean>(true);
  const { system } = useContext(HomeContext);

  const location = useLocation();

  const isSystemDetailsPage = location.pathname.indexOf("/system/") !== -1;
  const isSurrogateConfigPage =
    location.pathname.indexOf("/surrogate-config/") !== -1;
  const isSurrogateEvaluationPage =
    location.pathname.indexOf("/surrogate-evaluation/") !== -1;
  const isOptimizationPage = location.pathname.indexOf("/optimization/") !== -1;
  const isOptimizationResultPage =
    location.pathname.indexOf("/optimization-result/") !== -1;
  const isHomePage =
    location.pathname === "/start" || location.pathname === "/";
  const isCpcPage =
    location.pathname.indexOf("/candidate-point-calculation/") !== -1;

  return (
    <section className="surrogate-sidebar-container">
      <aside className="surrogate-sidebar">
        <nav className="sidebar-nav">
          <ul>
            <li>
              <Link
                className={`flex items-center w-full h-12 px-4 hover:bg-gray-900 rounded ${
                  isHomePage && "bg-gray-900 text-white"
                }`}
                to="/start"
              >
                <span className={`ml-2 text-sm font-medium duration-200`}>
                  Start
                </span>
              </Link>
            </li>
            {system?.id && (
              <>
                <li>
                  <Link
                    className={`flex items-center w-full justify-between h-12 px-4 rounded ${
                      isSystemDetailsPage && "bg-gray-900 text-white"
                    }`}
                    to={`/system-summary/${system?.id}`}
                  >
                    <span className={`ml-2 text-sm  font-medium duration-200 `}>
                      System
                    </span>
                    <span className="float-right">
                      <ArrowRight />
                    </span>
                  </Link>

                  <ul className="nav-flyout rounded">
                    <li className="border-solid border-b-2 pb-1">
                      <h1>System</h1>
                    </li>
                    <li>
                      <Link
                        className={`flex items-center w-full h-12 px-4 rounded `}
                        to={`/system-summary/${system?.id}`}
                      >
                        <span
                          className={`ml-2 text-sm font-medium duration-200`}
                        >
                          System Summary
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`flex items-center w-full h-12 px-4 rounded `}
                        to={`/system-create/${system?.id}`}
                      >
                        <span
                          className={`ml-2 text-sm font-medium duration-200`}
                        >
                          System Create
                        </span>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link
                    className={`flex items-center w-full h-12 px-4 rounded ${
                      (isSurrogateConfigPage || isSurrogateEvaluationPage) &&
                      "bg-gray-900 text-white"
                    }`}
                    to={`/surrogate-config/${system?.id}`}
                  >
                    <span className={`ml-2 text-sm font-medium duration-200 `}>
                      Surrogate
                    </span>
                    <span className="float-right">
                      <ArrowRight />
                    </span>
                  </Link>
                  <ul className="nav-flyout rounded">
                    <li className="border-solid border-b-2 pb-1">
                      <h1>Surrogate</h1>
                    </li>
                    <li>
                      <Link
                        className={`flex items-center w-full h-12 px-4 rounded ${
                          isSurrogateConfigPage && "bg-gray-900 text-white"
                        }`}
                        to={`/surrogate-config/${system?.id}`}
                      >
                        <span
                          className={`ml-2 text-sm font-medium duration-200`}
                        >
                          Surrogate Configuration
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`flex items-center w-full h-12 px-4 rounded ${
                          isSurrogateEvaluationPage && "bg-gray-900 text-white"
                        }`}
                        to={`/surrogate-evaluation/${system?.id}`}
                      >
                        <span
                          className={`ml-2 text-sm font-medium duration-200`}
                        >
                          Surrogate Evaluation
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`flex items-center w-full h-12 px-4 rounded ${
                          isCpcPage && "bg-gray-900 text-white"
                        }`}
                        to={`/candidate-point-calculation/${system?.id}`}
                      >
                        <span
                          className={`ml-2 text-sm font-medium duration-200 `}
                        >
                          Candidate Point Calculation
                        </span>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link
                    className={`flex items-center w-full h-12 px-4 rounded ${
                      (isOptimizationPage || isOptimizationResultPage) &&
                      "bg-gray-900 text-white"
                    }`}
                    to={`/optimization/${system?.id}`}
                  >
                    <span className={`ml-2 text-sm font-medium duration-200 `}>
                      Optimization
                    </span>
                    <span className="float-right">
                      <ArrowRight />
                    </span>
                  </Link>
                  <ul className="nav-flyout rounded">
                    <li className="border-solid border-b-2 pb-1">
                      <h1>Optimization</h1>
                    </li>
                    <li>
                      <Link
                        className={`flex items-center w-full h-12 px-4 rounded ${
                          isOptimizationPage && "bg-gray-900 text-white"
                        }`}
                        to={`/optimization/${system?.id}`}
                      >
                        <span
                          className={`ml-2 text-sm font-medium duration-200`}
                        >
                          Optimization
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`flex items-center w-full h-12 px-4 rounded ${
                          isOptimizationResultPage && "bg-gray-900 text-white"
                        }`}
                        to={`/optimization-result/${system?.id}`}
                      >
                        <span
                          className={`ml-2 text-sm font-medium duration-200 `}
                        >
                          Optimization Result
                        </span>
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            )}
          </ul>
        </nav>
      </aside>
    </section>
  );
};

export default Sidebar;
