export const convertBase64 = async (
  file: Blob
): Promise<string | undefined | ArrayBuffer | null> => {
  var reader = new FileReader();
  reader.readAsDataURL(file as Blob);

  return new Promise((resolve, reject) => {
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const getSystemFromSymmetry = (data: any) => {
  let independent;
  let dependent;
  // If the variables key is populated, use it directly
  if (data.variables && data.variables.inputs.length > 0) {
    independent = data.variables?.inputs.map((variable: any) => {
      return { name: variable.source_path, description: variable.display_name };
    });
    dependent = data.variables?.outputs.map((variable: any) => {
      return { name: variable.source_path, description: variable.display_name };
    });
  } else {
    // If variables aren't defined, fall back older parsing
    independent = data?.selected_system?.independent_variables?.map(
      (v: any) => {
        return { name: v.name ?? v.path, description: v.user_name ?? v.path };
      }
    );
    if (data?.selected_system?.dependent_variables?.length) {
      dependent = [
        ...data?.selected_system?.dependent_variables?.map((v: any) => {
          return { name: v.name ?? v.path, description: v.user_name ?? v.path };
        }),
      ];
      if (data?.selected_system?.constraint_variables?.length) {
        dependent = [
          ...dependent,
          ...data?.selected_system?.constraint_variables?.map((v: any) => {
            return {
              name: v.name ?? v.path,
              description: v.user_name ?? v.path,
            };
          }),
        ];
      }
    }
  }
  const system = {
    name: data.file_name,
    description: " from " + data.source,
    variables: {
      independent,
      dependent,
    },
  };
  console.log("getSystemFromSymmetry returning", system);
  return system;
};

export const getCreateSystemRequest = (name: string, data: any) => {
  const independent = data?.variables?.independent?.map((variable: any) => {
    return { name: variable.name, description: variable.user_name };
  });
  const dependent = [
    ...data?.variables?.dependent?.map((variable: any) => {
      return { name: variable.name, description: variable.user_name };
    }),
    ...(data?.variables?.constraint?.length
      ? data.variables.constraint.map((variable: any) => {
          return { name: variable.name, description: variable.user_name };
        })
      : []),
  ];
  const req = {
    name: name,
    description: data?.name + " from " + data?.source,
    variables: {
      independent,
      dependent,
    },
  };
  return req;
};

export const getCreateInfoSourcesRequest = async (
  file: Blob,
  filename: string
) => {
  const vsym_data_url = await convertBase64(file);
  const vsymB64 = (vsym_data_url as string).replace(
    "data:application/octet-stream;base64,",
    ""
  );
  const req = {
    name: "symmetry",
    description: filename,
    sampler: {
      type: "symmetry",
      vsym_b64: vsymB64,
    },
  };
  return req;
};

export const getCreateConfigurationRequest = (
  name: string,
  batchSize: string,
  epochs: string,
  infoSourceId: string,
  independent_bounds: any
) => {
  const req = {
    name: name,
    description: "",
    candidate_point_calculation: {
      type: "surrogate-model-generation",
      input_bounds: independent_bounds,
      acquisition_function: "distance-squared",
      default_num_samples: 1,
    },
    model_training: {
      type: "mlp",
      batch_size: batchSize,
      epochs: epochs,
      training_info_source_ids: [infoSourceId],
    },
  };
  return req;
};

export const getCreateAcquisitionRequest = (
  type: string,
  num_samples: string,
  var_bounds: any
) => {
  const req = {
    design: {
      type: type,
      num_samples: num_samples,
      bounds: var_bounds,
    },
  };
  return req;
};

export const getCreateOptimizationRequest = (
  branches: string,
  dependent_variable: string,
  optimization_direction: string,
  fixed_values: any,
  constrained_values: any,
  constrained_inputs: any
) => {
  // Remove blank values from fixed_values
  console.log(`getCreateOptimizationRequest: ${constrained_inputs}`);
  let reformatted_fixed_values: any = {};
  for (let key in fixed_values) {
    if (fixed_values[key] !== "") {
      reformatted_fixed_values[key] = [fixed_values[key]];
    }
  }

  let reformatted_constrained_inputs: any = {};
  Object.keys(constrained_inputs).forEach((key: any) => {
    let variable: any = constrained_inputs[key];
    reformatted_constrained_inputs = {
      ...reformatted_constrained_inputs,
      [key]: [
        variable.min === "" ? [null] : [variable.min],
        variable.max === "" ? [null] : [variable.max],
      ],
    };
  });

  // Format constraints as tuples
  let constraint_bounds = {};
  Object.keys(constrained_values).forEach((key: any) => {
    let variable: any = constrained_values[key];
    constraint_bounds = {
      ...constraint_bounds,
      [key]: [
        variable.min === "" ? null : variable.min,
        variable.max === "" ? null : variable.max,
      ],
    };
  });
  const req = {
    constrained_outputs: constraint_bounds,
    fixed_independent_variables: reformatted_fixed_values,
    n_branches: branches,
    objective_output: dependent_variable,
    optimization_type: optimization_direction,
    constrained_inputs: reformatted_constrained_inputs,
  };
  return req;
};

export const getCreateVisualizationRequest = (
  visualization_type: string,
  variable_name: string,
  variable_description: string,
  width: number = 500,
  height: number = 700
) => {
  const req = {
    visualization: {
      type: visualization_type,
      width: width,
      height: height,
      variable: variable_name,
      title: `${variable_description} Truth vs Prediction`,
    } as any,
    export_format: "png",
  };
  return req;
};
