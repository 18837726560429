import React, { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { InformationCircleIcon } from "@heroicons/react/solid";
import { IVariable } from "../types/System";

interface IAddVariableForm {
  open: boolean;
  type: string;
  setOpen: (value: boolean) => void;
  handleAdd: Function;
  validateVariable: Function;
}

export default function AddVariableForm({
  open,
  type,
  setOpen,
  handleAdd,
  validateVariable,
}: IAddVariableForm) {
  const cancelButtonRef = useRef(null);

  const [newVar, setNewVar] = useState<IVariable>({
    name: "",
    description: "",
  });

  const handleInputChange = (e: any, prop: string) => {
    e.preventDefault();
    setNewVar({ ...newVar, [prop]: e.target.value });
  };

  const handleAddButton = (e: any) => {
    e.preventDefault();
    console.log("Calling handleAdd w/", newVar);
    if (newVar.name.length === 0 || newVar.description.length === 0) {
      alert("Both fields are required.");
      return false;
    }
    if (!validateVariable(newVar)) {
      alert("A varible with that path already exists.");
      return false;
    }
    handleAdd(newVar);
  };

  useEffect(() => {
    setNewVar({
      name: "",
      description: "",
    });
  }, [open]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-4 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="m-3 text-center sm:text-left w-full">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900 mb-4"
                      >
                        Add Variable
                      </Dialog.Title>
                      <div className="mt-2 w-full">
                        <div className="md:flex md:items-center mb-6">
                          <div className="md:w-3/12 lg:1/5">
                            <label
                              id="configuration-title-label"
                              className="block text-gray-700 text-md"
                              htmlFor="configuration-title-input"
                            >
                              Variable Path
                              <span title="Variable Path">
                                <InformationCircleIcon className="h-5 w-5 inline ml-1" />
                              </span>
                            </label>
                          </div>
                          <div className="md:w-9/12 lg:4/5">
                            <input
                              id="var-name"
                              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                              type="text"
                              value={newVar.name}
                              onChange={(e) => handleInputChange(e, "name")}
                              required={true}
                              minLength={1}
                            />
                          </div>
                        </div>
                        <div className="md:flex md:items-center mb-6">
                          <div className="md:w-3/12 lg:1/5">
                            <label
                              id="configuration-description-label"
                              className="block text-gray-700 text-md"
                              htmlFor="user-display-name"
                            >
                              Display Name
                              <span title="Display Name">
                                <InformationCircleIcon className="h-5 w-5 inline ml-1" />
                              </span>
                            </label>
                          </div>
                          <div className="md:w-9/12 lg:4/5">
                            <input
                              onChange={(e) =>
                                handleInputChange(e, "description")
                              }
                              id="user-display-name"
                              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                              type="text"
                              value={newVar.description}
                              required={true}
                              minLength={1}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-slbvoxblue hover:bg-blue-700 px-3 py-2 text-sm font-semibold shadow-sm  sm:ml-3 sm:w-auto"
                    onClick={handleAddButton}
                  >
                    Add
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
