import { InformationCircleIcon } from "@heroicons/react/solid";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { HomeContext } from "../contexts/HomeContext";
import { authorize, get_auth_header } from "../apis/axios";
import { useOidc } from "../helpers/oidc";

const CandidatePointCalculation = () => {
  const { isAuthenticated, getAccessTokenSilently } = useOidc();
  const [configurationId, setConfigurationId] = useState<string>("");
  const [surrogateId, setSurrogateId] = useState<string>("");
  const [dependentVariable, setDependentVariable] = useState<string>("");
  const [enableSubmit, setEnableSubmit] = useState<boolean>(true);
  const [cpcResult, setCpcResult] = useState<any>([]);
  const [cpcRecieved, setCpcRecieved] = useState<boolean>(false);
  const [cpcId, setCpcId] = useState<string>("");
  const [cpcStatus, setCpcStatus] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const {
    getSystemDetails,
    getConfigurations,
    getSurrogate,
    getSurrogates,
    getOptimization,
    createCandidatePointCalculation,
    system,
  } = useContext(HomeContext);
  const { systemId } = useParams();

  const delay = 2000;

  async function addAuthorization() {
    if (isAuthenticated) {
      let token = await getAccessTokenSilently();
      if (token) {
        authorize(token);
      }
    }
  }

  const getSystemSummary = async () => {
    !get_auth_header() && (await addAuthorization());
    !system && (await getSystemDetails(systemId));

    console.log(system, systemId);
  };

  useEffect(() => {
    getSystemSummary();
  }, []);

  const getConfigurationId = async () => {
    console.log("In getConfigurationId systemId:", systemId);
    if (system && system.id) {
      let configurationsResponse = await getConfigurations(system.id);
      console.log(
        "getConfigurations configurationsResponse:",
        configurationsResponse
      );
      console.log(
        "getConfigurations configurationsResponse.length:",
        configurationsResponse.length
      );
      if (configurationsResponse && configurationsResponse.length) {
        console.log("settingConfigurationId:", configurationsResponse[0].id);
        setConfigurationId(configurationsResponse[0].id);
      }
    }
  };

  const getSurrogateId = async () => {
    console.log("In getSurrogateId systemId:", systemId, configurationId);
    if (system && system.id && configurationId) {
      let surrogatesResponse = await getSurrogates(system.id, configurationId);
      console.log("getSurrogates surrogatesResponse:", surrogatesResponse);
      console.log(
        "getSurrogates surrogatesResponse.length:",
        surrogatesResponse.length
      );
      if (surrogatesResponse && surrogatesResponse.length) {
        setSurrogateId(surrogatesResponse[0].id);
      }
    }
  };

  const checkCpcStatus = async () => {
    if (cpcId && ["in-progress", "pending"].indexOf(cpcStatus) > -1) {
      let cpcResponse = await getOptimization(
        systemId,
        configurationId,
        surrogateId,
        cpcId,
        false
      );
      setCpcStatus(cpcResponse.task_state);
      if (cpcResponse.task_state == "completed") {
        setEnableSubmit(true);
        setCpcRecieved(true);
        setCpcResult(cpcResponse.result.candidate_points[0].recommended_inputs);
        setErrorMessage("");
      } else if (cpcResponse.task_state == "failed") {
        setEnableSubmit(true);
        setErrorMessage("Error: " + cpcResponse.error);
      }
    }
  };

  useEffect(() => {
    let id: any = undefined;
    if (
      cpcId &&
      ["Checking", "in-progress", "pending"].indexOf(cpcStatus) > -1
    ) {
      id = setInterval(checkCpcStatus, delay);
    }
    return () => clearInterval(id);
  }, [cpcId, cpcStatus]);

  const submitCPC = async () => {
    setEnableSubmit(false);
    let cpcResponse = await createCandidatePointCalculation(
      systemId,
      configurationId,
      surrogateId,
      1,
      dependentVariable
    );
    setCpcId(cpcResponse.id);
    setCpcStatus(cpcResponse.task_state);
  };

  useEffect(() => {
    getConfigurationId();
  }, [systemId, system]);

  useEffect(() => {
    getSurrogateId();
  }, [systemId, configurationId, system]);

  const independentVars = system?.variables?.independent;
  const dependentVariables = system?.variables?.dependent || [];
  const enableButton =
    enableSubmit && dependentVariable !== "" && surrogateId && configurationId;

  return isAuthenticated ? (
    <>
      <div className="md:flex md:items-center mt-8 w-full text-center dark:text-white">
        <div className="md:w-8/12 lg:4/5">
          <h1 className="text-2xl font-bold leading-7 text-gray-700 dark:text-white ml-28 sm:truncate sm:text-3xl sm:tracking-tight">
            Candidate Point Calculation
          </h1>
        </div>
      </div>

      <div className="md:flex md:items-center mt-4 mb-4 w-full text-center dark:text-white">
        <div className="md:w-4/12">
          <label
            id="dependentvar-select-label"
            className="block text-gray-700 text-md dark:text-white"
            htmlFor="batch-size"
          >
            Select dependent variable
            <span title="What dependent variable should be used for CPC?">
              <InformationCircleIcon className="h-5 w-5 inline ml-1" />
            </span>
          </label>
        </div>
        <div className="md:w-4/12">
          <select
            id="dependentvar-select"
            className="shadow  border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none dark:bg-slbgray5d  focus:shadow-outline dark:text-white dark:text-white"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setDependentVariable(e.target.value);
            }}
            value={dependentVariable}
          >
            <option value="">Select</option>
            {dependentVariables.map((varibl, idx) => (
              <option key={idx} value={varibl.name}>
                {varibl.description} ({varibl.name})
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className=" md:items-top mb-8 w-full block">
        <div className="inline-block md:w-6/12 lg:6/12 text-right">
          <button
            id="submit-button"
            onClick={submitCPC}
            className={`bg-slbvoxblue ${
              enableButton
                ? "hover:bg-blue-700 dark:hover:bg-blue-900"
                : "bg-opacity-40"
            } dark:bg-slbvoxblued dark:text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}
            disabled={!enableButton}
          >
            Submit
          </button>
        </div>
      </div>
      {cpcRecieved ? (
        <div className="md:w-4/12" id="cpc-results">
          {independentVars?.map((variable: any, idx) => (
            <div key={idx}>
              <strong>{variable.description}</strong>
              <span title={variable.name}>
                <InformationCircleIcon className="h-5 w-5 inline ml-1" />
              </span>
              : {cpcResult[variable.name]}
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
      <div className="md:flex md:items-center mt-8 w-full text-center dark:text-white">
        <div className="md:w-8/12 lg:4/5">
          <div className="font-bold leading-7 text-red-500 dark:text-red-400 ml-28 sm:tracking-tight">
            {errorMessage}
          </div>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};

export default CandidatePointCalculation;
