import { InformationCircleIcon } from "@heroicons/react/solid";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { HomeContext } from "../contexts/HomeContext";
import { authorize, get_auth_header } from "../apis/axios";
import { Link } from "react-router-dom";
import loader from "../images/loading.gif";
import { toast } from "react-toastify";
import { useOidc } from "../helpers/oidc";
import { ISystem } from "../types/System";

interface IMetricBox {
  system: ISystem | undefined;
  surrogateDetails: any;
  data_tip: string;
  error_label: string;
  error_prop: string;
  label_id: string;
}

function MetricBox({
  system,
  surrogateDetails,
  data_tip,
  error_label,
  error_prop,
  label_id,
}: IMetricBox) {
  return (
    <div className="md:flex md:items-center my-8 w-full text-center dark:text-white">
      <div className="md:w-3/12 lg:1/5">
        <label
          id={label_id}
          className="block text-gray-700 text-md dark:text-white"
        >
          {error_label}
          <span title={data_tip}>
            <InformationCircleIcon className="h-5 w-5 inline ml-1" />
          </span>
        </label>
      </div>
      <div className="md:w-4/12 lg:3/5">
        <ul>
          {system?.variables?.dependent.length === 0 ? (
            <li>No Dependent Variables Configured</li>
          ) : (
            system?.variables?.dependent.map((v, idx) => (
              <li key={v.name}>
                {v.description}:{" "}
                {surrogateDetails?.test_metrics?.[error_prop]?.[v.name]}
              </li>
            ))
          )}
        </ul>
      </div>
    </div>
  );
}

const SurrogateEvaluation = () => {
  const { isAuthenticated, getAccessTokenSilently } = useOidc();
  const [parsedData, setParsedData] = useState<any>();
  const [infoSourceId, setInfoSourceId] = useState<string>("");
  const [configurationId, setConfigurationId] = useState<string>("");
  const [surrogateId, setSurrogateId] = useState<string>("");
  const [surrogateStatus, setSurrogateStatus] = useState<string>("");
  const [surrogateDetails, setSurrogateDetails] = useState<any>();
  const [downloading, setDownloading] = useState<boolean>(false);
  const [vizStatus, setVizStatus] = useState("None loaded.");
  const [vizDataUrl, setVizDataUrl] = useState("");
  const {
    getSystemDetails,
    getConfigurations,
    getSurrogate,
    getSurrogates,
    createVisualization,
    getOnnx,
    system,
  } = useContext(HomeContext);
  const { systemId } = useParams();
  const delay = 2000;

  async function addAuthorization() {
    if (isAuthenticated) {
      let token = await getAccessTokenSilently();
      if (token) {
        authorize(token);
      }
    }
  }

  const getSystemSummary = async () => {
    !get_auth_header() && (await addAuthorization());
    !system && (await getSystemDetails(systemId));
  };

  useEffect(() => {
    getSystemSummary();
  }, []);

  const getConfigurationId = async () => {
    console.log("In getConfigurationId systemId:", systemId);
    if (system && system.id) {
      let configurationsResponse = await getConfigurations(system.id);
      console.log(
        "getConfigurations configurationsResponse:",
        configurationsResponse
      );
      console.log(
        "getConfigurations configurationsResponse.length:",
        configurationsResponse.length
      );
      if (configurationsResponse && configurationsResponse.length) {
        setConfigurationId(configurationsResponse[0].id);
      }
    }
  };

  const getSurrogateId = async () => {
    console.log("In getSurrogateId systemId:", systemId, configurationId);
    if (system && system.id && configurationId) {
      let surrogatesResponse = await getSurrogates(system.id, configurationId);
      console.log("getSurrogates surrogatesResponse:", surrogatesResponse);
      console.log(
        "getSurrogates surrogatesResponse.length:",
        surrogatesResponse.length
      );
      if (surrogatesResponse && surrogatesResponse.length) {
        setSurrogateId(surrogatesResponse[0].id);
      }
    }
  };

  const getSurrogateDetails = async () => {
    console.log("In getSurrogateDetails surrogateId:", surrogateId);
    if (system && system.id && configurationId && surrogateId) {
      let surrogateResponse = await getSurrogate(
        system.id,
        configurationId,
        surrogateId
      );
      console.log("getSurrogate surrogateResponse:", surrogateResponse);
      if (surrogateResponse && surrogateResponse.id) {
        setSurrogateDetails(surrogateResponse);
      }
    }
  };

  useEffect(() => {
    getConfigurationId();
  }, [systemId, system]);

  useEffect(() => {
    getSurrogateId();
  }, [systemId, configurationId, system]);

  useEffect(() => {
    getSurrogateDetails();
  }, [surrogateId, system]);

  let handleValidationPlotClick = (variable: any) => {
    async function requestAndProcessVisualization(variable: any) {
      let reader = new FileReader();
      try {
        let visualizationResponse = await createVisualization(
          systemId,
          configurationId,
          surrogateId,
          "train-test-plot",
          variable.name,
          variable.description,
          // square dimensions recommended for train-test-plot
          700,
          700
        );

        /* Convert response for rendering */
        reader.onloadend = () => {
          setVizDataUrl(reader.result as any);
        };

        setVizStatus("");
        reader.readAsDataURL(visualizationResponse);
      } catch (err: any) {
        let error = await err.response?.data?.text();
        try {
          error = JSON.parse(error)?.detail;
        } catch {
          console.log("JSON parse error", error);
        }
      }
    }

    if (system && system.id && configurationId && surrogateId) {
      setVizDataUrl(""); // Clear any current visualization
      setVizStatus("Loading");
      requestAndProcessVisualization(variable);
    }
  };

  const downloadOnnx = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    console.log(
      "In downloadOnnx: sysId, confId, surrId:",
      systemId,
      configurationId,
      surrogateId
    );
    if (systemId && configurationId && surrogateId) {
      setDownloading(true);
      const onnxResponse = await getOnnx(
        systemId,
        configurationId,
        surrogateId
      );
      setDownloading(false);
      var blob = new Blob([onnxResponse], { type: "application/onnx" });
      let url = window.URL.createObjectURL(blob);

      // Creating the hyperlink and auto click it to start the download
      let link = document.createElement("a");
      link.href = url;
      link.download = `${system?.name}_gen${surrogateDetails?.generation}.onnx`;
      link.click();
    } else {
      console.log("ONNX export is unavailable.");
      toast.error("ONNX export is unavailable.");
    }
  };

  return isAuthenticated ? (
    <>
      <div className="md:flex md:items-center my-8 w-full text-center dark:text-white">
        <div className="md:w-8/12 lg:4/5">
          <h1 className="text-2xl font-bold leading-7 text-gray-700 dark:text-white ml-28 sm:truncate sm:text-3xl sm:tracking-tight">
            Surrogate Evaluation
          </h1>
        </div>
      </div>
      <div className="md:flex md:items-center my-2 w-full text-center dark:text-white">
        <div className="md:w-8/12 lg:4/5">
          <h1 className="text-xl text-left font-bold leading-7 text-gray-700 dark:text-white ml-28 sm:truncate sm:text-2xl sm:tracking-tight">
            Error Metrics
          </h1>
        </div>
      </div>

      <MetricBox
        system={system}
        surrogateDetails={surrogateDetails}
        data_tip="The average squared difference between the estimated value and the actual value."
        error_label="Mean Squared Error"
        error_prop="mean_squared_error"
        label_id="mean-squared-error"
      />

      <MetricBox
        system={system}
        surrogateDetails={surrogateDetails}
        data_tip="The average absolute difference between the estimated value and the actual value."
        error_label="Mean Absolute Error"
        error_prop="mean_absolute_error"
        label_id="mean-absolute-error"
      />

      <MetricBox
        system={system}
        surrogateDetails={surrogateDetails}
        data_tip="The amount of variance explained by the model."
        error_label="Explained Variance Score"
        error_prop="explained_variance_score"
        label_id="explained-variance-score"
      />

      <MetricBox
        system={system}
        surrogateDetails={surrogateDetails}
        data_tip="The average pinball loss between the estimated value and the actual value."
        error_label="Mean Pinball Loss"
        error_prop="mean_pinball_loss"
        label_id="mean-pinball-loss"
      />

      <MetricBox
        system={system}
        surrogateDetails={surrogateDetails}
        data_tip="Correlation between the estimated value and the actual value."
        error_label="R2 Score"
        error_prop="r2_score"
        label_id="r2-score"
      />

      <div className="md:flex md:items-center my-2 w-full text-center dark:text-white">
        <div className="md:w-8/12 lg:4/5">
          <h1 className="text-xl text-left font-bold leading-7 text-gray-700 dark:text-white ml-28 sm:truncate sm:text-2xl sm:tracking-tight">
            Truth vs Prediction Plots
          </h1>
        </div>
      </div>
      <div className="md:flex md:items-center mb-8 w-full text-center dark:text-white">
        <div className="md:w-3/12 lg:1/5">
          <ul>
            {system?.variables?.dependent.length === 0 ? (
              <li>No Dependent Variables Configured</li>
            ) : (
              system?.variables?.dependent.map((v, idx) => (
                <li key={v.name} className="mb-4">
                  {v.description}
                  <button
                    id="submit-button-{idx}"
                    onClick={(e) => handleValidationPlotClick(v)}
                    className={`bg-red-500 hover:bg-red-700 dark:text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
                      system && system.id && configurationId && surrogateId
                        ? ""
                        : "bg-opacity-40"
                    }`}
                    disabled={
                      !(system && system.id && configurationId && surrogateId)
                    }
                  >
                    Show
                  </button>
                </li>
              ))
            )}
          </ul>
        </div>
        <div className="md:w-9/12 lg:4/5 mw-700">
          {vizDataUrl !== "" ? (
            <img
              id="viz-image"
              src={vizDataUrl}
              alt="Visualization from server"
            />
          ) : (
            <div>{vizStatus}</div>
          )}
        </div>
      </div>
      <div className="md:flex md:items-center my-2 w-full text-center dark:text-white">
        <div className="md:w-8/12 lg:4/5">
          <h1 className="text-xl text-left font-bold leading-7 text-gray-700 dark:text-white ml-28 sm:truncate sm:text-2xl sm:tracking-tight">
            Surrogate Operations
          </h1>
        </div>
      </div>

      <div className="text-left mb-8 w-full ">
        <div className="inline-block md:w-6/12 lg:6/12 text-right">
          <button
            id="download-onnx"
            onClick={downloadOnnx}
            className={`bg-slbvoxblue hover:bg-blue-700 dark:hover:bg-blue-900 dark:bg-slbvoxblued dark:text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
              systemId && configurationId && surrogateId ? "" : "bg-opacity-40"
            }`}
            disabled={!(systemId && configurationId && surrogateId)}
          >
            Download ONNX{" "}
            {downloading && <img className="w-5 inline" src={loader} />}
          </button>
        </div>
        <div className="inline-block" id="optimization-wrapper">
          <Link
            className={`ml-4 py-3 px-4 rounded border cursor-pointer bg-white text-black hover:bg-gray-300 hover:text-black`}
            to={`/optimization/${system?.id}`}
          >
            Optimization
          </Link>
        </div>
        <div className="inline-block" id="candidate-point-calculation-wrapper">
          <Link
            className={`ml-4 py-3 px-4 rounded border cursor-pointer bg-white text-black hover:bg-gray-300 hover:text-black`}
            to={`/candidate-point-calculation/${system?.id}`}
          >
            Candidate Point Calculation
          </Link>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};

export default SurrogateEvaluation;
