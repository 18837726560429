import axios from "axios";
import AppSettings from "./AppSettings";

import _ from "lodash";

export const axiosInstance = axios.create({
  baseURL: AppSettings.endpoints.facadeUrl,
  timeout: AppSettings.endpoints.timeout,
});

export const authorize = (token: string) => {
  axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export function get_auth_header() {
  return axiosInstance.defaults.headers.common["Authorization"];
}

interface IData {
  errors?: string | null;
  mesage?: string;
  message?: string | null;
  result?: Object;
}

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      const { data }: { data: Object } = error.response;
      if (data) {
        const { errors = null }: IData = data;
        //Look for error here
        if (_.isArray(errors)) {
          const err = _.last(errors);
          if (err) {
            throw new Error(err.detail);
          }
        } else if (_.isObject(data)) {
          const { mesage: tmp_mesage, result }: IData = data;
          const { message = null }: IData = result || data || {};
          const msg = message || tmp_mesage;
          if (_.isString(msg)) {
            throw new Error(msg);
          }
        } else {
          throw new Error(data);
        }
      }
    }
    throw error;
  }
);
