import React, { ComponentType, useEffect, FC } from "react";
import { AuthContextProps, useAuth, UserManager } from "oidc-react";
import { User, OidcMetadata } from "oidc-client-ts";
import AppSettings from "../apis/AppSettings";

const metadata_seed = (authority: string): Partial<OidcMetadata> => {
  /**
   * Provide default values for settings not exposed through
   * an authority's openid discovery url
   */
  return {};
};

export const oidcConfig = {
  onSignIn: async (user: any) => {
    window.location.hash = "";
  },
  userManager: new UserManager({
    authority: AppSettings.endpoints.facadeUrl,
    client_id: AppSettings.clientId,
    redirect_uri: window.location.origin,
    silent_redirect_uri: window.location.origin,
    post_logout_redirect_uri: window.location.origin,
    response_type: "code",
    scope: "openid profile email http://geminus.ai/facade",
    loadUserInfo: true,
    metadataSeed: metadata_seed(AppSettings.endpoints.facadeUrl),
  }),
};

export interface OidcProps extends AuthContextProps {
  isAuthenticated: boolean;
  loginWithRedirect: (args?: unknown) => Promise<void>;
  logout: (args?: unknown) => Promise<void>;
  getAccessTokenSilently: () => Promise<string | undefined>;
  user?: User | null;
}

export const useOidc = (): OidcProps => {
  const auth = useAuth();
  return {
    ...auth,
    isAuthenticated: Boolean(auth && auth.userData),
    loginWithRedirect: auth.signIn,
    logout: auth.signOutRedirect,
    user: auth.userData,
    getAccessTokenSilently: async () => auth.userData?.access_token,
  };
};

const defaultOnRedirecting = (): JSX.Element => <></>;

const defaultReturnTo = (): string =>
  `${window.location.pathname}${window.location.search}`;

/**
 * Options for the withAuthenticationRequired Higher Order Component
 */
export interface WithAuthenticationRequiredOptions {
  /**
   * ```js
   * withAuthenticationRequired(Profile, {
   *   returnTo: '/profile'
   * })
   * ```
   *
   * or
   *
   * ```js
   * withAuthenticationRequired(Profile, {
   *   returnTo: () => window.location.hash.substr(1)
   * })
   * ```
   *
   * Add a path for the `onRedirectCallback` handler to return the user to after login.
   */
  returnTo?: string | (() => string);
  /**
   * ```js
   * withAuthenticationRequired(Profile, {
   *   onRedirecting: () => <div>Redirecting you to the login...</div>
   * })
   * ```
   *
   * Render a message to show that the user is being redirected to the login.
   */
  onRedirecting?: () => JSX.Element;
}

/**
 * ```js
 * const MyProtectedComponent = withAuthenticationRequired(MyComponent);
 * ```
 *
 * When you wrap your components in this Higher Order Component
 * and an anonymous user visits your component they will be
 * redirected to the login page and returned to the page they
 * were redirected from after login.
 */
export const withAuthenticationRequired = <P extends object>(
  Component: ComponentType<P>,
  options: WithAuthenticationRequiredOptions = {}
): FC<P> => {
  return function WithAuthenticationRequired(props: P): JSX.Element {
    const { returnTo = defaultReturnTo, onRedirecting = defaultOnRedirecting } =
      options;

    const { isAuthenticated, isLoading, loginWithRedirect } = useOidc();

    /**
     * The route is authenticated if the user has valid auth
     * */
    const routeIsAuthenticated = isAuthenticated;

    useEffect(() => {
      if (isLoading || routeIsAuthenticated) {
        return;
      }
      const opts = {
        appState: {
          returnTo: typeof returnTo === "function" ? returnTo() : returnTo,
        },
      };
      (async (): Promise<void> => {
        await loginWithRedirect(opts);
      })();
    }, [isLoading, routeIsAuthenticated, loginWithRedirect, returnTo]);

    return routeIsAuthenticated ? <Component {...props} /> : onRedirecting();
  };
};
