import React, { useRef, useEffect } from "react";
import LogOutButton from "./LogoutButton";
import { useOidc } from "../helpers/oidc";
import DefaultAvatar from "../images/avatar.svg";

function Dialog({ setEllipsisDialog }: { setEllipsisDialog: Function }) {
  const { user } = useOidc();
  const picture = user?.profile.picture || DefaultAvatar;
  const name = user?.profile.name;
  const dialogRef = useRef();

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);

    function handleClick(e: any) {
      if (dialogRef && dialogRef.current) {
        const ref: any = dialogRef.current;
        if (!ref.contains(e.target)) {
          setEllipsisDialog(false);
        }
      }
    }
  }, [setEllipsisDialog]);
  return (
    <div
      ref={dialogRef as any}
      className="bg-transparent flex items-end justify-end mr-0 relative "
      style={{ marginLeft: "-20rem", top: "40px" }}
    >
      <div className="md:w-80 rounded shadow-lg p-6 dark:bg-gray-800 bg-white z-50 relative">
        <div className="sm:flex items-start">
          <img
            src={picture}
            className="hidden lg:block h-8 w-auto rounded-full"
            alt="Alternate"
          />
          <h1 className="dark:text-gray-100 text-gray-800 ml-2 font-bold text-lg">
            {name}
          </h1>
        </div>
        <div className="py-4 text-base font-medium  dark:text-gray-100 text-gray-800">
          <LogOutButton />
        </div>
      </div>
    </div>
  );
}

export default Dialog;
