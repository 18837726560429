export interface IProgressBar {
  progress: number;
  text?: string;
  total?: number;
  classNames?: string;
}

const ProgressBar = ({
  progress,
  total,
  text,
  classNames = "h-2 w-full bg-gray-300 rounded-lg overflow-hidden",
}: IProgressBar) => {
  const progressWidth: string | number | undefined =
    progress > 0 && total ? (Number(progress) / Number(total)) * 100 : progress;
  return (
    <>
      {text && <span>{text}</span>}
      <div className={classNames}>
        <div
          style={{
            width: `${progressWidth}%`,
          }}
          className="h-full bg-blue-500 transition-all duration-1000"
        />
      </div>
    </>
  );
};

export default ProgressBar;
