import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import MainLoader from "../components/common/MainLoader";
import { HomeContext } from "../contexts/HomeContext";
import { ISystem } from "../types/System";
import { useOidc } from "../helpers/oidc";

const Home = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedSystem, setSelectedSystem] = useState<ISystem | undefined>(
    undefined
  );
  const [dragActive, setDragActive] = useState(false);
  const [file, setFile] = useState<Blob>();
  const [isValidFile, setIsValidFile] = useState<boolean>(true);
  const [fileName, setFileName] = useState<string>();
  const [fileUploadStatus, setFileUploadStatus] = useState<string>();
  const [loading, setLoading] = useState<boolean>();
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const { isAuthenticated } = useOidc();
  const { uploadCaseFile, mainLoader, symmetrySystems } =
    useContext(HomeContext);
  const navigate = useNavigate();
  const menuRef = useRef();

  // hide menu on outside click
  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);

    function handleClick(e: any) {
      console.log("handleClick");
      if (menuRef && menuRef.current) {
        const ref: any = menuRef.current;
        if (!ref.contains(e.target)) {
          setShowMenu(false);
        }
      }
    }
  }, []);

  const handleDrag = function (e: any) {
    console.log("handleDrag e.type:", e.type);
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  //file selection by drag and drop
  const handleDrop = function (e: any) {
    console.log("handleDrop", e.dataTransfer);
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      console.log("handleDrop", e.dataTransfer.files[0]);
      setFile(e.dataTransfer.files[0]);
      const fileName = e.dataTransfer.files[0]?.name;
      setFileName(fileName);
      const extn = fileName?.split(".").pop();
      if (extn !== "vsym") {
        setIsValidFile(false);
        return;
      }
      setIsValidFile(true);
    }
  };

  // dropdown
  const toggleMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowMenu(!showMenu);
  };
  const handleSystemChange = (system: ISystem) => {
    console.log("handleSystemChange");
    setSelectedSystem(system);
    navigate(`/system-summary/${system.id}`);
  };

  //file selection by browse
  const handleChange = (e: any) => {
    console.log("handleChange e.target:", e.target);
    e.preventDefault();
    setFile(e.target.files[0]);
    const fileName = e.target.files[0]?.name;
    setFileName(fileName);
    const extn = fileName?.split(".").pop();
    if (extn !== "vsym") {
      setIsValidFile(false);
      return;
    }
    setIsValidFile(true);
  };

  const submitFile = async (e: any) => {
    console.log("submitFile");
    e.preventDefault();

    if (file && isValidFile && !loading) {
      setLoading(true);
      const response: any = await uploadCaseFile(file);
      console.log(response);
      if (response?.data) {
        setFileUploadStatus("success");
      } else {
        setFileUploadStatus("failed");
      }
      setLoading(false);
    }
  };

  const onButtonClick = () => {
    inputRef?.current?.click();
  };

  return isAuthenticated ? (
    <>
      {mainLoader && <MainLoader />}

      {fileUploadStatus === "failed" && (
        <div
          className="flex p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
          role="alert"
        >
          <svg
            aria-hidden="true"
            className="flex-shrink-0 inline w-5 h-5 mr-3"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="https://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span className="sr-only">Info</span>
          <div>
            Something went wrong!
            <span className="font-medium">
              <Link to="/start"> Try again. </Link>
            </span>
          </div>
        </div>
      )}
      <div className="md:flex md:items-center mb-8 pt-8 sm:w-full md:w-10/12 max-w-xl ">
        <div className="md:w-6/12 lg:w-2/5">
          <label className="block  text-md" htmlFor="info-source-selector">
            Symmetry model
          </label>
        </div>
        <div className="md:w-6/12 lg:w-3/5 ">
          <div className="relative inline-block text-left">
            <div ref={menuRef as any}>
              <button
                type="button"
                className="inline-flex w-full justify-center rounded-md border bg-white dark:bg-gray-700 border-gray-300  px-4 py-2 text-sm font-medium  shadow-sm  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                id="menu-button"
                aria-expanded="true"
                aria-haspopup="true"
                onClick={toggleMenu}
              >
                {selectedSystem?.name || "Select case"}
                <svg
                  className="-mr-1 ml-2 h-5 w-5"
                  xmlns="https://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>

            {showMenu && (
              <div
                className="absolute max-h-96 w-auto overflow-y-auto left-0 z-10 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white dark:bg-gray-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
                tabIndex={-1}
              >
                {symmetrySystems.map((model, idx) => (
                  <div
                    className="py-1"
                    key={idx}
                    role="none"
                    onClick={() => handleSystemChange(model)}
                  >
                    <span
                      className=" block px-4 py-2 text-sm"
                      role="menuitem"
                      tabIndex={-1}
                    >
                      {model.name}
                    </span>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="max-w-xl sm:w-full">
        <>
          {(!file || !isValidFile) && (
            <form
              id="form-file-upload"
              onDragEnter={handleDrag}
              onSubmit={(e) => e.preventDefault()}
            >
              <input
                ref={inputRef}
                type="file"
                id="input-file-upload"
                className="hidden"
                accept=".vsym"
                onChange={handleChange}
              />
              <label className="flex justify-center w-full h-32 px-4 transition bg-white dark:bg-gray-600 dark:text-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none drag-active:bg-white">
                <span className="flex items-center space-x-2">
                  <span className="font-medium ">
                    Drop a file or click to{" "}
                    <button onClick={onButtonClick}>browse</button>
                  </span>
                </span>
              </label>
              {dragActive && (
                <div
                  className="absolute w-full h-full inset-0"
                  id="drag-file-element"
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                ></div>
              )}
              {!isValidFile && (
                <span className="border border-yellow-300 bg-yellow-50 dark:bg-yellow-200">
                  Please select a valid file. eg: file.vsym
                </span>
              )}
            </form>
          )}
          {file && (
            <div className="flex items-center justify-end pr-2 mt-4">
              <div className="w-full">
                <span>
                  <svg
                    className="w-10"
                    xmlns="https://www.w3.org/2000/svg"
                    shapeRendering="geometricPrecision"
                    textRendering="geometricPrecision"
                    imageRendering="optimizeQuality"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    viewBox="0 0 380 511.7"
                  >
                    <path
                      fillRule="nonzero"
                      d="M26.18 0h221.14c3.1 0 5.85 1.51 7.56 3.84l122.88 145.08a9.27 9.27 0 0 1 2.21 6.05l.03 330.55c0 7.13-2.98 13.68-7.72 18.42l-.03.04c-4.75 4.74-11.29 7.72-18.43 7.72H26.18c-7.13 0-13.69-2.96-18.45-7.71l-.03-.04C2.97 499.22 0 492.69 0 485.52V26.18C0 19 2.95 12.46 7.68 7.72l.04-.04C12.46 2.95 19 0 26.18 0zm335.06 164.7c-134.78-5.58-134.35-17.38-129.82-134.02l.45-11.92H26.18c-2.05 0-3.91.83-5.26 2.16a7.482 7.482 0 0 0-2.16 5.26v459.34c0 2.02.84 3.88 2.18 5.23 1.36 1.35 3.22 2.19 5.24 2.19h327.64c2.01 0 3.86-.85 5.22-2.2 1.35-1.36 2.2-3.21 2.2-5.22V164.7zM250.25 27.32l-.15 4.01c-3.73 96.04-4.22 109.01 100.23 114.16L250.25 27.32z"
                    ></path>
                  </svg>
                </span>
                {fileName}
              </div>
              {/* {loading && <Loader />} */}
              <button
                id="submit-button"
                onClick={submitFile}
                className={`bg-slbvoxblue hover:bg-blue-700  ${
                  (loading || !isValidFile) && "bg-gray-500 hover:bg-gray-500"
                } dark:bg-slbvoxblued text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}
              >
                Upload
              </button>
            </div>
          )}
        </>
      </div>
    </>
  ) : (
    <div className="flex h-56 grid grid-cols-3 gap-4 justify-center content-center">
      Please login!
    </div>
  );
};

export default Home;
