import React, { useContext, useEffect, useState } from "react";
import VariableFixedInput from "../components/VariableFixedInput";
import VariableRangeInput from "../components/VariableRangeInput";
import { HomeContext } from "../contexts/HomeContext";
import { authorize, get_auth_header } from "../apis/axios";
import { Link, useParams } from "react-router-dom";
import MainLoader from "../components/common/MainLoader";
import { InformationCircleIcon } from "@heroicons/react/solid";
import { useOidc } from "../helpers/oidc";

const Optimization = () => {
  const { isAuthenticated, getAccessTokenSilently } = useOidc();
  const { systemId } = useParams();
  const delay = 2000;

  const {
    cancelOperation,
    createOptimization,
    getOptimization,
    getOptimizations,
    getSystemDetails,
    getConfigurations,
    getSurrogates,
    haveSeenThisFile,
    mainLoader,
    system,
  } = useContext(HomeContext);

  const [configurationId, setConfigurationId] = useState<string>("");
  const [surrogateId, setSurrogateId] = useState<string>("");
  const [surrogateStatus, setSurrogateStatus] = useState<string>("Checking");
  const [parsedData, setParsedData] = useState<any>();
  const [optimizationId, setOptimizationId] = useState<string>("");
  const [invalid, setInvalid] = useState<boolean>(false);
  const [optimizationStatus, setOptimizationStatus] =
    useState<string>("Checking");

  const [dependentVariable, setDependentVariable] = useState<string>("");

  const inputVarValues = system?.variables?.independent;
  const [outputVarRanges, setOutputVarRanges] = useState(
    system?.variables?.dependent
  );
  const dependentVariables = system?.variables?.dependent || [];

  const [parsedOutputBounds, setParsedOutputBounds] = useState<any>({});

  const [variableFixedInputValues, setVariableFixedInputValues] = useState<any>(
    {}
  );
  const [outputConstraintsValues, setOutputConstraintsValues] = useState<any>(
    {}
  );
  const [inputConstraintsValues, setInputConstraintsValues] = useState<any>({});

  const [numberOfBranches, setNumberOfBranches] = useState<any>(3);
  const [optimizationDirection, setOptimizationDirection] =
    useState<string>("maximize");

  const [errorMessage, setErrorMessage] = useState<string>("");

  async function addAuthorization() {
    if (isAuthenticated) {
      let token = await getAccessTokenSilently();
      if (token) {
        authorize(token);
      }
    }
  }

  const getSystemSummary = async () => {
    !get_auth_header() && (await addAuthorization());
    !system && (await getSystemDetails(systemId));
  };

  useEffect(() => {
    getSystemSummary();
  }, []);

  useEffect(() => {
    let id: any = undefined;
    if (
      optimizationId &&
      ["Checking", "in-progress", "pending"].indexOf(optimizationStatus) > -1
    ) {
      id = setInterval(checkOptimizationStatus, delay);
    }
    return () => clearInterval(id);
  }, [optimizationId, optimizationStatus]);

  // Get configurations and set the latest as configurationId
  const getConfigurationId = async () => {
    console.log("In getConfigurationId system:", system);
    if (system?.id) {
      let configurationsResponse = await getConfigurations(system.id);
      console.log(
        "getConfigurations configurationsResponse:",
        configurationsResponse
      );
      let configuration_id = configurationsResponse?.[0]?.id;
      if (configuration_id) {
        setConfigurationId(configuration_id);
      }
    }
  };

  // Get surrogates and set the latest as surrogateId
  const getSurrogateId = async () => {
    console.log("In getSurrogateId systemId:", systemId, configurationId);
    if (system && system.id && configurationId) {
      let surrogatesResponse = await getSurrogates(system.id, configurationId);
      console.log("getSurrogates surrogatesResponse:", surrogatesResponse);
      console.log(
        "getSurrogates surrogatesResponse.length:",
        surrogatesResponse.length
      );
      if (surrogatesResponse) {
        if (surrogatesResponse.length) {
          setSurrogateId(surrogatesResponse[0].id);
          setSurrogateStatus(surrogatesResponse[0].creation_state);
        } else {
          setSurrogateStatus("None found.");
        }
      }
    }
  };

  // Get optimizations and set the latest as optimizationId
  const getOptimizationId = async () => {
    console.log("In getOptimizationId surrogateId:", surrogateId);
    if (system && system.id && configurationId && surrogateId) {
      let optimizationsResponse = await getOptimizations(
        system.id,
        configurationId,
        surrogateId
      );
      console.log(
        "getOptimizations optmizationsResponse:",
        optimizationsResponse
      );
      let optimization = optimizationsResponse?.[0];
      if (optimization) {
        setNumberOfBranches(optimization.request.n_branches);
        setOptimizationDirection(optimization.request.optimization_type);
        setOptimizationId(optimization.id);
        setVariableFixedInputValues(
          optimization.request.fixed_independent_variables
        );
        setDependentVariable(optimization.request.objective_output);
      } else {
        setOptimizationStatus("None found.");
      }
    }
  };

  const getParsedData = async () => {
    console.log("In getParsedData systemId:", systemId);
    if (system && system.name) {
      console.log("getParsedData system.name:", system.name);
      let parsed_data = haveSeenThisFile({ name: system.name });
      console.log("getParsedData: parsed_data:", parsed_data);
      if (parsed_data) {
        setParsedData(parsed_data);
      }
    }
  };

  const getVariableName = (
    name_or_description: string,
    place: string = "both"
  ) => {
    /*
    if (["independent", "both", "inputs"].indexOf(place) != -1) {
      for (let i: number = 0; i < (system?.variables?.independent.length ?? 0); i++) {
        if (system?.variables?.independent[i].name == name_or_description || system?.variables?.independent[i].description == name_or_description) {
          return system.variables.independent[i].name;
        }
      }
    }
    if (["dependent", "both", "outputs"].indexOf(place) != -1) {
      for (let i: number = 0; i < (system?.variables?.dependent.length ?? 0); i++) {
        if (system?.variables?.dependent[i].name == name_or_description || system?.variables?.dependent[i].description == name_or_description) {
          return system.variables.dependent[i].name;
        }
      }
    }
    */
    return "variable_not_found";
  };

  const getDefaultsFromParsedData = async () => {
    console.log(
      "In getDefaultsFromParsedData system name:",
      system?.name,
      " parsedData: ",
      parsedData?.file_name
    );
    if (parsedData && parsedData.file_name) {
      let optimization_var: string = "";
      let to_optimize = parsedData.selected_system;

      console.log("selected_system");
      console.dir(to_optimize);

      // Set the default output bounds.
      let parsed_bounds: any = {};
      to_optimize.constraint_variables.forEach((v: any) => {
        // default min,max to 0,1 if not define so we always end up with bounds defined
        // once user provided bounds is implemented, this defaulting may either be removed
        // or the user can override
        let min = v.min_value !== null ? v.min_value.value : 0;
        let max = v.max_value !== null ? v.max_value.value : 1;
        parsed_bounds = {
          ...parsed_bounds,
          //[getVariableName(v.name || v.user_name, "outputs")]: { type: "explicit", min: min, max: max },
          [v.name]: { type: "explicit", min: min, max: max },
        };
      });
      console.log("parsed_bounds (constraints)", parsed_bounds);
      setParsedOutputBounds(parsed_bounds);

      // 2. Set the default input values.
      let fixed_values: any = {};
      to_optimize.independent_variables.forEach((v: any) => {
        // Is optimizer_value? val_for_best_val? ever populated?
        // If min and max, take the mid point.
        let min = v.min_value !== null ? v.min_value.value : 0;
        let max = v.max_value !== null ? v.max_value.value : 1;
        let value = (min + max) / 2;
        //fixed_values = { ...fixed_values, [getVariableName(v.name ?? v.user_name, "inputs")]: value };
        fixed_values = {
          ...fixed_values,
          [v.name]: { min: value, max: value },
        };
      });
      console.log("fixed_values (inputs)", fixed_values);
      setInputConstraintsValues(fixed_values);

      // 3. Set the variable to optimize. ("There can be only one.")
      // Constraint variables are stored separately, so this is the short list.
      for (let i: number = 0; i < to_optimize.dependent_variables.length; i++) {
        if (to_optimize.dependent_variables[i].active === true) {
          console.log("Optimization var (from parsed data 'path') is:");
          console.dir(to_optimize.dependent_variables[i]);
          optimization_var = to_optimize.dependent_variables[i].path;
          console.log("Trying path", optimization_var);
          if (optimization_var === null || optimization_var === undefined) {
            optimization_var = to_optimize.dependent_variables[i].name;
            console.log("Trying name", optimization_var);
          }
          console.log("Final dep var", optimization_var);
          break;
        }
      }

      /* 4. Set depVar */
      setDependentVariable(optimization_var);
    }
  };

  // Try to continue if a configuration already exists
  useEffect(() => {
    getParsedData();
  }, [system]);

  useEffect(() => {
    getConfigurationId();
  }, [system]);

  useEffect(() => {
    getSurrogateId();
  }, [systemId, configurationId, system]);

  useEffect(() => {
    getOptimizationId();
  }, [systemId, surrogateId, system]);

  useEffect(() => {
    getDefaultsFromParsedData();
  }, [parsedData]);

  useEffect(() => {
    console.log(
      "dep var or output bounds change depVar:[",
      dependentVariable,
      "]"
    );
    console.dir(parsedOutputBounds);
    if (
      dependentVariable !== null &&
      dependentVariable !== undefined &&
      (Object.entries(parsedOutputBounds).length > 0 ||
        (outputVarRanges && outputVarRanges.length > 0) ||
        Object.entries(outputConstraintsValues).length > 0)
    ) {
      // Bootstrap the output variable list and ranges
      let new_outputVarRange = system?.variables?.dependent.filter(
        (v) => v.name !== dependentVariable
      );
      console.log("useEffect parsedOutputBounds", parsedOutputBounds);
      let new_outputConstraintValues = {
        ...parsedOutputBounds,
      };
      delete new_outputConstraintValues[dependentVariable];
      console.log("useEffect depVar new vars", new_outputVarRange);
      console.log("useEffect depVar new values", new_outputConstraintValues);
      setOutputVarRanges(new_outputVarRange);
      setOutputConstraintsValues(new_outputConstraintValues);
    }
  }, [dependentVariable, parsedOutputBounds, system]);

  //dependent variable selection
  const handleDependentVariableChange = (variable: string) => {
    console.log("handleDepVarChange ", variable);
    let new_outputVarRange = system?.variables?.dependent.filter(
      (v) => v.name !== variable
    );
    console.log("parsed", parsedOutputBounds);
    console.log("current", outputConstraintsValues);
    let new_outputConstraintValues = {
      ...parsedOutputBounds,
      ...outputConstraintsValues,
    };
    delete new_outputConstraintValues[variable];
    console.log("handleDepVarChange new values", new_outputConstraintValues);
    setDependentVariable(variable);
    setOutputVarRanges(new_outputVarRange);
    setOutputConstraintsValues(new_outputConstraintValues);
  };

  const submitOptimization = async (e: any) => {
    e.preventDefault();
    //  validation
    if (numberOfBranches === "" || isNaN(numberOfBranches)) {
      setInvalid(true);
      return;
    } else {
      setInvalid(false);
    }
    console.log("submitFixedInputConstraints", variableFixedInputValues);
    console.log("outputConstraintsValues", outputConstraintsValues);

    if (system && systemId && surrogateId) {
      // Create optimization
      if (!optimizationId) {
        let optimizationResponse = await createOptimization(
          systemId,
          configurationId,
          surrogateId,
          numberOfBranches,
          dependentVariable,
          optimizationDirection,
          variableFixedInputValues,
          outputConstraintsValues,
          inputConstraintsValues
        );
        console.log("optimizationResponse:", optimizationResponse);
        console.log("optimizationResponse.data:", optimizationResponse.data);
        if (
          optimizationResponse &&
          optimizationResponse.data &&
          optimizationResponse.data.id
        ) {
          setErrorMessage("");
          console.log(
            "optimizationResponse.data.id:",
            optimizationResponse.data.id
          );
          setOptimizationId(optimizationResponse.data.id);
          setOptimizationStatus(optimizationResponse.data.task_state);
        } else if (optimizationResponse.response.data.detail) {
          setErrorMessage(
            `Error: ${optimizationResponse.response.data.detail}`
          );
        }
      } else {
        console.log("Surrogate is unavailable.");
      }
    }
  };

  const checkOptimizationStatus = async () => {
    console.log(
      "In checkOptStatus systemId",
      systemId,
      "surrogateId:",
      surrogateId,
      "optId:",
      optimizationId,
      optimizationStatus
    );
    if (
      surrogateId &&
      optimizationId &&
      ["Checking", "in-progress", "pending"].indexOf(optimizationStatus) > -1
    ) {
      console.log("checkOptStatus getOpt");
      let optimizationResponse = await getOptimization(
        systemId,
        configurationId,
        surrogateId,
        optimizationId
      );
      console.log("optimizationResponse", optimizationResponse);
      console.log(
        "optimizationResponse.task_state",
        optimizationResponse.task_state
      );
      if (optimizationResponse && optimizationResponse.task_state) {
        let task_state = optimizationResponse.task_state;
        if (
          optimizationResponse.task_state === "failed" &&
          optimizationResponse.error
        ) {
          task_state += `: ${optimizationResponse.error}`;
        }
        setOptimizationStatus(task_state);
      }
    }
  };

  const cancelCurrentOptimization = async () => {
    cancelOperation(systemId, configurationId, surrogateId, optimizationId);
  };

  let enableCancel = optimizationStatus === "in-progress";

  return (
    <>
      {mainLoader && <MainLoader />}
      <div className="md:flex md:items-center mt-8 w-full text-center dark:text-white">
        <div className="md:w-8/12 lg:4/5">
          <h1 className="text-2xl font-bold leading-7 text-gray-700 dark:text-white ml-28 sm:truncate sm:text-3xl sm:tracking-tight">
            Optimization
          </h1>
        </div>
      </div>
      <div className="flex items-start justify-items-start flex-wrap text-left">
        <div className="md:flex md:items-center mt-4 w-full text-center dark:text-white">
          <div className="md:w-4/12">
            <label
              id="dependentvar-select-label"
              className="block text-gray-700 text-md dark:text-white"
              htmlFor="batch-size"
            >
              Select dependent variable to optimize
              <span title="What dependent variable should be optimized?">
                <InformationCircleIcon className="h-5 w-5 inline ml-1" />
              </span>
            </label>
          </div>
          <div className="md:w-4/12">
            <select
              id="dependentvar-select"
              className="shadow  border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none dark:bg-slbgray5d  focus:shadow-outline dark:text-white dark:text-white"
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                handleDependentVariableChange(e.target.value);
              }}
              value={dependentVariable}
            >
              <option value="">Select</option>
              {dependentVariables.map((varibl, idx) => (
                <option key={idx} value={varibl.name}>
                  {varibl.description} ({varibl.name})
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="md:flex md:items-center mt-4 w-full text-center dark:text-white">
          <div className="md:w-4/12">
            <label
              id="minmax-select-label"
              className="block text-gray-700 text-md dark:text-white"
              htmlFor="minmax-select"
            >
              Minimize or maximize?
              <span title="Minimize or maximize the above variable?">
                <InformationCircleIcon className="h-5 w-5 inline ml-1" />
              </span>
            </label>
          </div>
          <div className="md:w-4/12">
            <select
              id="minmax-select"
              className="shadow  border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none dark:bg-slbgray5d  focus:shadow-outline dark:text-white dark:text-white"
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setOptimizationDirection(e.target.value);
              }}
              value={optimizationDirection}
            >
              <option value="">Select</option>
              <option value="minimize">Minimize</option>
              <option value="maximize">Maximize</option>
            </select>
          </div>
        </div>
        <div className="md:flex md:items-center mt-4 w-full text-center dark:text-white">
          <div className="md:w-4/12 ">
            <label
              id="branches"
              className="block text-gray-700 text-md dark:text-white"
              htmlFor="branches"
            >
              Number of branches?
              <span title="How many concurrent paths should be tried to optimize the variable?">
                <InformationCircleIcon className="h-5 w-5 inline ml-1" />
              </span>
            </label>
          </div>
          <div className="md:w-4/12">
            <input
              id="branches-select"
              type="number"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none dark:bg-slbgray5d  focus:shadow-outline dark:text-white dark:text-white"
              value={numberOfBranches}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setNumberOfBranches(e.target.value);
              }}
            />
            {invalid && (
              <div className="w-full text-left p-1 text-sm text-red-700">
                Please enter a value{" "}
              </div>
            )}
          </div>
        </div>
      </div>
      {/* 
      <VariableFixedInput
        title="Fixed Value Input Variables"
        variables={inputVarValues}
        tooltip="The value of inputs if known and fixed. If not known or fixed, leave blank."
        values={variableFixedInputValues}
        setValues={setVariableFixedInputValues}
        switchDescription={true}
      />
      */}

      <VariableRangeInput
        title="Input Constraint Bounds"
        tooltip="The range of values that are acceptable for each input. If not constrained, leave blank."
        variables={inputVarValues}
        values={inputConstraintsValues}
        setValues={setInputConstraintsValues}
        switchDescription={true}
      ></VariableRangeInput>
      <VariableRangeInput
        title="Output Constraint Bounds"
        tooltip="The range of values that are acceptable for each output. If not constrained, leave blank."
        variables={outputVarRanges}
        values={outputConstraintsValues}
        setValues={setOutputConstraintsValues}
        switchDescription={true}
      ></VariableRangeInput>
      <div className="md:flex md:items-center mb-8 w-full text-center dark:text-white">
        <div className="md:w-3/12 lg:1/5">
          <label
            id="status"
            className="block text-gray-700 text-md dark:text-white"
            htmlFor="SurrogateCreationStatus"
          >
            Surrogate Creation Status
            <span title="Surrogate Created and Available">
              <InformationCircleIcon className="h-5 w-5 inline ml-1" />
            </span>
          </label>
        </div>
        <div className="md:w-4/12 lg:3/5">{surrogateStatus}</div>
      </div>
      <div className="md:flex md:items-center mb-8 w-full text-center dark:text-white">
        <div className="md:w-3/12 lg:1/5">
          <label
            id="status"
            className="block text-gray-700 text-md dark:text-white"
            htmlFor="SurrogateCreationStatus"
          >
            Optimization Creation Status
            <span title="Optimization Created and Complete">
              <InformationCircleIcon className="h-5 w-5 inline ml-1" />
            </span>
          </label>
        </div>
        <div className="md:w-4/12 lg:3/5" id="optimization-status">
          {optimizationStatus}
        </div>
        <button
          id="cancel-button"
          onClick={cancelCurrentOptimization}
          className={`bg-red-500 ${
            enableCancel
              ? "hover:bg-red-700 dark:hover:bg-red-900"
              : "bg-opacity-40"
          } dark:text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}
          disabled={!enableCancel}
        >
          Cancel
        </button>
      </div>

      <div className="md:flex md:items-center mt-8 w-full text-center dark:text-white">
        <div className="md:w-8/12 lg:4/5">
          <div className="font-bold leading-7 text-red-500 dark:text-red-400 ml-28 sm:tracking-tight">
            {errorMessage}
          </div>
        </div>
      </div>
      <div className=" md:items-top mb-8 w-full block">
        <div className="inline-block md:w-6/12 lg:6/12 text-right">
          <button
            id="submit-fixed-input-constraints"
            onClick={(e) => submitOptimization(e)}
            className={`bg-slbvoxblue ${
              system && systemId && surrogateId && !optimizationId
                ? "hover:bg-blue-700"
                : "bg-opacity-40"
            } dark:bg-slbvoxblued dark:text-white font-bold py-2 mt-4 px-4 rounded focus:outline-none focus:shadow-outline`}
            disabled={!(system && systemId && surrogateId && !optimizationId)}
          >
            Submit
          </button>
        </div>

        <div className="inline-block md:w-6/12 lg:6/12">
          <Link
            className={`ml-4 py-3 px-4 rounded border cursor-pointer bg-white text-black hover:bg-gray-300 hover:text-black`}
            to={`/optimization-result/${system?.id}`}
          >
            Optimization Result
          </Link>
        </div>
      </div>
    </>
  );
};

export default Optimization;
