import { InformationCircleIcon } from "@heroicons/react/solid";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { HomeContext } from "../contexts/HomeContext";
import { authorize, get_auth_header } from "../apis/axios";
import { useOidc } from "../helpers/oidc";

const OptimizationResult = () => {
  const { isAuthenticated, getAccessTokenSilently } = useOidc();
  const [configurationId, setConfigurationId] = useState<string>("");
  const [surrogateId, setSurrogateId] = useState<string>("");
  const [surrogateStatus, setSurrogateStatus] = useState<string>("");
  const [optimizationId, setOptimizationId] = useState<string>("");
  const [optimizationStatus, setOptimizationStatus] =
    useState<string>("Checking");
  const [optimizationResponse, setOptimizationResponse] = useState<any>();
  const {
    getSystemDetails,
    getConfigurations,
    getSurrogates,
    getOptimization,
    getOptimizations,
    system,
  } = useContext(HomeContext);
  const { systemId } = useParams();
  const delay = 2000;

  async function addAuthorization() {
    if (isAuthenticated) {
      let token = await getAccessTokenSilently();
      if (token) {
        authorize(token);
      }
    }
  }

  const getSystemSummary = async () => {
    !get_auth_header() && (await addAuthorization());
    !system && (await getSystemDetails(systemId));
  };

  useEffect(() => {
    getSystemSummary();
  }, []);

  useEffect(() => {
    let id: any = undefined;
    if (
      optimizationId &&
      ["Checking", "in-progress"].indexOf(optimizationStatus) > -1
    ) {
      id = setInterval(checkOptimizationStatus, delay);
    }
    return () => clearInterval(id);
  }, [optimizationId, optimizationStatus]);

  // Get configurations and set the latest as configurationId
  const getConfigurationId = async () => {
    console.log("In getConfigurationId systemId:", systemId);
    if (systemId) {
      let configurationsResponse = await getConfigurations(systemId);
      console.log(
        "getConfigurations configurationsResponse:",
        configurationsResponse
      );
      console.log(
        "getConfigurations configurationsResponse.length:",
        configurationsResponse.length
      );
      if (configurationsResponse && configurationsResponse.length) {
        setConfigurationId(configurationsResponse[0].id);
      }
    }
  };

  // Get surrogates and set the latest as surrogateId
  const getSurrogateId = async () => {
    console.log("In getSurrogateId configurationId:", configurationId);
    if (system && system.id && configurationId) {
      let surrogatesResponse = await getSurrogates(system.id, configurationId);
      console.log("getSurrogates surrogatesResponse:", surrogatesResponse);
      console.log(
        "getSurrogates surrogatesResponse.length:",
        surrogatesResponse.length
      );
      if (surrogatesResponse) {
        if (surrogatesResponse.length) {
          setSurrogateId(surrogatesResponse[0].id);
          setSurrogateStatus(surrogatesResponse[0].creation_state);
        } else {
          setSurrogateStatus("None found.");
        }
      }
    }
  };

  // Get optimizations and set the latest as optimizationId
  const getOptimizationId = async () => {
    console.log("In getOptimizationId surrogateId:", surrogateId);
    if (system && system.id && configurationId && surrogateId) {
      let optimizationsResponse = await getOptimizations(
        system.id,
        configurationId,
        surrogateId
      );
      console.log(
        "getOptimizations optmizationsResponse:",
        optimizationsResponse
      );
      let optimization_id = optimizationsResponse?.[0]?.id;
      if (optimization_id) {
        setOptimizationId(optimization_id);
      } else {
        setOptimizationStatus("None found.");
      }
    }
  };

  // Try to continue if a configuration already exists
  useEffect(() => {
    getConfigurationId();
  }, [system]);

  useEffect(() => {
    getSurrogateId();
  }, [systemId, configurationId, system]);

  useEffect(() => {
    getOptimizationId();
  }, [systemId, surrogateId, system]);

  const checkOptimizationStatus = async () => {
    console.log(
      "In checkOptStatus systemId",
      systemId,
      "surrogateId:",
      surrogateId,
      "optId:",
      optimizationId,
      optimizationStatus
    );
    if (
      surrogateId &&
      optimizationId &&
      ["Checking", "in-progress"].indexOf(optimizationStatus) > -1
    ) {
      console.log("checkOptStatus getOpt");
      let optimizationResponse = await getOptimization(
        systemId,
        configurationId,
        surrogateId,
        optimizationId
      );
      console.log("optimizationResponse", optimizationResponse);
      console.log(
        "optimizationResponse.task_state",
        optimizationResponse.task_state
      );
      if (optimizationResponse && optimizationResponse.task_state) {
        let task_state = optimizationResponse.task_state;
        if (optimizationResponse.task_state === "failed") {
          task_state += `: ${optimizationResponse.error}`;
        }
        setOptimizationStatus(task_state);
        setOptimizationResponse(optimizationResponse);
      }
    }
  };

  function displayWithRequestContext(
    responseData: any,
    var_name: any,
    var_type: string,
    var_value_str?: any
  ) {
    console.log(
      "displayWithRequestContext",
      responseData,
      "var_name",
      var_name,
      "var_type",
      var_type,
      "var_value_str"
    );
    if (!responseData) {
      return;
    }
    if (var_type === "independent") {
      console.log("indep request", responseData?.request);
      if (
        responseData?.request["fixed_independent_variables"].hasOwnProperty(
          var_name
        )
      ) {
        return `${responseData?.request["fixed_independent_variables"][var_name]} (fixed)`;
      } else if (
        responseData?.request["constrained_inputs"].hasOwnProperty(var_name)
      ) {
        let min = responseData?.request["constrained_inputs"][var_name][0][0];
        let max = responseData?.request["constrained_inputs"][var_name][1][0];
        if (min !== null) {
          if (max !== null) {
            return `${var_value_str} (constrained ${min}-${max})`;
          } else {
            return `${var_value_str} (constrained ≥${min})`;
          }
        } else {
          if (max !== null) {
            return `${var_value_str} (constrained ≤${max})`;
          } else {
            return `${var_value_str} (varying)`;
          }
        }
      } else {
        return `${var_value_str} (varying)`;
      }
    } else {
      if (var_name === responseData.request["objective_output"]) {
        return `${var_value_str} (${responseData?.request["optimization_type"]})`;
      } else if (
        responseData?.request &&
        var_name in responseData?.request["constrained_outputs"]
      ) {
        return `${var_value_str} (range: ${responseData?.request["constrained_outputs"][var_name]})`;
      }
    }
    return `${var_value_str}`;
  }

  return isAuthenticated ? (
    <>
      <div className="md:flex md:items-center my-8 w-full text-center dark:text-white">
        <div className="md:w-8/12 lg:4/5">
          <h1 className="text-2xl font-bold leading-7 text-gray-700 dark:text-white ml-28 sm:truncate sm:text-3xl sm:tracking-tight">
            Optimization Result
          </h1>
        </div>
      </div>
      <div className="md:flex md:items-center mb-8 w-full text-center dark:text-white">
        <div className="md:w-3/12 lg:1/5">
          <label
            id="status"
            className="block text-gray-700 text-md dark:text-white"
            htmlFor="SurrogateCreationStatus"
          >
            Surrogate Creation Status
            <span title="Surrogate Created and Available">
              <InformationCircleIcon className="h-5 w-5 inline ml-1" />
            </span>
          </label>
        </div>
        <div className="md:w-4/12 lg:3/5">{surrogateStatus}</div>
      </div>
      <div className="md:flex md:items-center mb-8 w-full text-center dark:text-white">
        <div className="md:w-3/12 lg:1/5">
          <label
            id="status"
            className="block text-gray-700 text-md dark:text-white"
            htmlFor="SurrogateCreationStatus"
          >
            Optimization Creation Status
            <span title="Optimization Created and Complete">
              <InformationCircleIcon className="h-5 w-5 inline ml-1" />
            </span>
          </label>
        </div>
        <div className="md:w-4/12 lg:3/5">{optimizationStatus}</div>
      </div>
      <div className="md:flex md:items-center justify-center my-2 w-full text-center dark:text-white">
        <div className="md:flex w-full md:items-top mb-8 text-center">
          <div className="px-5 md:w-3/6">
            <label className="block mb-1 text-lg" htmlFor="formGridCode_month">
              Independent Variables
            </label>
            <div className="w-full border bg-white dark:bg-gray-700  rounded mx-4 px-3 py-2 outline-none h-auto">
              <ul className="text-left text-md">
                {system?.variables?.independent.length === 0 ? (
                  <li>No Independent Variables Configured</li>
                ) : (
                  system?.variables?.independent.map((v, idx) => (
                    <li key={idx}>
                      <strong>{v.description}</strong>
                      <span title={v.name}>
                        <InformationCircleIcon className="h-5 w-5 inline ml-1" />
                      </span>{" "}
                      {displayWithRequestContext(
                        optimizationResponse,
                        v.name,
                        "independent",
                        optimizationResponse?.result?.x[v.name]
                      )}
                    </li>
                  ))
                )}
              </ul>
            </div>
          </div>
          <div className="px-5 md:w-3/6">
            <label className="block mb-1  text-lg" htmlFor="formGridCode_month">
              Dependent Variables
            </label>
            <div className="w-full border bg-white dark:bg-gray-700 rounded px-3 py-2 outline-none h-auto min-h-32">
              <ul className="text-left text-md">
                {system?.variables?.dependent.length === 0 ? (
                  <li>No Dependent Variables Configured</li>
                ) : (
                  system?.variables?.dependent.map((v, idx) => (
                    <li key={idx}>
                      <strong>{v.description}</strong>
                      <span title={v.name}>
                        <InformationCircleIcon className="h-5 w-5 inline ml-1" />
                      </span>{" "}
                      {displayWithRequestContext(
                        optimizationResponse,
                        v.name,
                        "dependent",
                        optimizationResponse?.result?.y[v.name]
                      )}
                    </li>
                  ))
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};

export default OptimizationResult;
