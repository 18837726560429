import { InformationCircleIcon } from "@heroicons/react/solid";
import React from "react";

export interface IVariableRangeInput {
  title: string;
  tooltip?: string;
  classNames?: string;
  variables: any[] | undefined;
  values: any;
  setValues: Function;
  switchDescription?: boolean;
}

const VariableRangeInput = ({
  title,
  tooltip,
  variables,
  classNames = "inline-block text-2xl sm:text-xl font-bold text-gray-700 tracking-tight dark:text-white",
  values,
  setValues,
  switchDescription = false,
}: IVariableRangeInput) => {
  const updateValueChange = (name: string, type: string, value: string) => {
    const currentValue = { [name]: { ...values[name], [type]: value } };

    setValues({ ...values, ...currentValue });
  };
  /* DEBUG
  console.log("rangeVariables", variables);
  console.log("rangeValues", values);
  */
  return (
    <>
      {title && (
        <h1
          className="group w-full text-left p-8 flex whitespace-pre-wrap pb-4"
          id={""}
        >
          <span className={classNames}>{title}</span>
          {tooltip && (
            <span title={tooltip}>
              <InformationCircleIcon className="h-5 w-5 inline ml-1" />
            </span>
          )}
        </h1>
      )}
      {variables?.map((variable: any, indx: number) => {
        return (
          <div
            key={indx}
            className="md:flex md:items-center mb-4 w-full text-center dark:text-white"
          >
            <div className="md:w-4/12 ">
              <span className="block break-all text-gray-700 text-md dark:text-white">
                {switchDescription
                  ? variable.description || variable.name
                  : variable.name}
                {variable.description && (
                  <span
                    title={
                      switchDescription ? variable.name : variable.description
                    }
                  >
                    <InformationCircleIcon className="h-5 w-5 inline ml-1" />
                  </span>
                )}
              </span>
            </div>
            <div className="md:w-8/12 text-left">
              {" "}
              <label htmlFor={`range-min-input-${indx}`}>Min</label>{" "}
              <input
                id={`range-min-input-${indx}`}
                type="number"
                className="shadow inline-block appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none dark:bg-slbgray5d  focus:shadow-outline dark:text-white dark:text-white"
                value={values[variable.name]?.min}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  updateValueChange(variable.name, "min", e.target.value);
                }}
              />{" "}
              <label className="ml-2" htmlFor={`range-max-input-${indx}`}>
                Max
              </label>{" "}
              <input
                id={`range-max-input-${indx}`}
                type="number"
                className="shadow inline-block appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none dark:bg-slbgray5d  focus:shadow-outline dark:text-white dark:text-white"
                value={values[variable.name]?.max}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  updateValueChange(variable.name, "max", e.target.value);
                }}
              />{" "}
              <label htmlFor={`range-unit-input-${indx}`}> Unit</label>{" "}
              <input
                id={`range-unit-input-${indx}`}
                type="string"
                className="shadow inline-block appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none dark:bg-slbgray5d  focus:shadow-outline dark:text-white dark:text-white"
                value={values[variable.name]?.unit}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  updateValueChange(variable.name, "unit", e.target.value);
                }}
              />{" "}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default VariableRangeInput;
