import React, { useState } from "react";
import LoginButton from "./LoginButton";
import Dialog from "./Dialog";
import { useOidc } from "../helpers/oidc";
import DefaultAvatar from "../images/avatar.svg";

const AuthenticationButton = () => {
  const [showEllipsisDialog, setEllipsisDialog] = useState(false);
  const { isAuthenticated, isLoading, user } = useOidc();
  const picture = user?.profile.picture || DefaultAvatar;

  return !isLoading ? (
    <nav>
      <div className="max-w-9xl mx-auto pr-4">
        <div className="relative flex items-center justify-between">
          <div className="flex flex-col absolute inset-y-0 right-0 items-end pr-2 sm:static sm:inset-auto sm:pr-0">
            {isAuthenticated && !isLoading ? (
              <button
                style={{ marginTop: showEllipsisDialog ? 0 : 0 }}
                className="hidden md:block flex items-end rounded-full focus:bg-gray-200 text-gray-400 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setEllipsisDialog(!showEllipsisDialog);
                }}
              >
                <img
                  src={picture}
                  className="h-10 w-auto border-2 rounded-full"
                  alt="Alternate"
                />
                <span className=" absolute md:-right-1 md:w-4 md:h-4 md:bg-green-600 md:border-2 md:border-white md:rounded-full md:top-1"></span>
              </button>
            ) : (
              <button className="bg-gray-300 pl-4 pr-4 pt-2 pb-2 rounded-md text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                <LoginButton />
              </button>
            )}
            <div className="absolute">
              {showEllipsisDialog && (
                <Dialog setEllipsisDialog={setEllipsisDialog} />
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  ) : (
    <></>
  );
};

export default AuthenticationButton;
