import { InformationCircleIcon, PencilAltIcon } from "@heroicons/react/solid";
interface IKeyValueItem {
  label: string;
  value: any;
  description?: string;
  isEditable?: boolean;
}

const KeyValueItem = ({
  label,
  value = "",
  description,
  isEditable,
}: IKeyValueItem) => {
  return (
    <div className="border-b dark:border-gray-700">
      <div className="py-1 px-6 text-md font-medium whitespace-nowrap ">
        {`${label} `}
        {description ? (
          <span data-tip={`${description}`}>
            <InformationCircleIcon className="h-5 w-5 inline ml-1" />
          </span>
        ) : (
          ""
        )}
        : <strong className="text-lg">{value}</strong>
        {isEditable && (
          <PencilAltIcon className="h-6 w-6 cursor-pointer inline ml-2" />
        )}
      </div>
    </div>
  );
};
export default KeyValueItem;
