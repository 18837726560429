const w = window as any;

export default {
  endpoints: {
    facadeUrl: w._env_.GEMINUS_API_URL,
    timeout: 120000,
  },
  webappVersion: w._env_.PLATFORM_VERSION,
  environment: w._env_.ENVIRONMENT,
  clientId: w._env_.GEMINUS_AUTH_CLIENT_ID,
};
