import { InformationCircleIcon } from "@heroicons/react/solid";
import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { HomeContext } from "../contexts/HomeContext";
import { INameValue } from "../types/System";
import { authorize, get_auth_header } from "../apis/axios";
import VariableRangeInput from "../components/VariableRangeInput";
import ComboBoxComponent from "../components/ComboBoxComponent";
import { numberOFSamplesOptions } from "../helpers/constants";
import { useOidc } from "../helpers/oidc";
import ProgressBar from "../components/ProgressBar";
import { relative } from "path";

const SurrogateConfig = () => {
  const { isAuthenticated, getAccessTokenSilently } = useOidc();
  const [batchSize, setBatchSize] = useState<string>("256");
  const [epochs, setEpochs] = useState<string>("4000");
  const [numberOfSamples, setNumberOfSamples] = useState<INameValue>(
    numberOFSamplesOptions[3]
  );
  const [parsedData, setParsedData] = useState<any>();
  const [infoSourceId, setInfoSourceId] = useState<string>("");
  const [configurationId, setConfigurationId] = useState<string>("");
  const [configurationStatus, setConfigurationStatus] =
    useState<string>("Checking");
  const [acquisitionId, setAcquisitionId] = useState<string>("");
  const [acquisitionStatus, setAcquisitionStatus] =
    useState<string>("Checking");
  const [surrogateId, setSurrogateId] = useState<string>("");
  const [invalid, setInvalid] = useState<any>({});
  const [surrogateStatus, setSurrogateStatus] = useState<string>("Checking");
  const [acquisitionResponseData, setAcquisitionResponse] = useState<any>(null);
  const {
    cancelAcquisition,
    createConfig,
    createAcquisition,
    createSurrogate,
    getSystemDetails,
    getAcquisition,
    getConfigurations,
    getSurrogate,
    getSurrogates,
    haveSeenThisFile,
    storeSampleAcquisitionId,
    retrieveSampleAcquisitionId,
    system,
  } = useContext(HomeContext);
  const [inputVarRanges, setInputVarRanges] = useState<any>();
  const [inputIndependentValues, setInputIndependentValues] = useState<any>({});
  const [pendingSubmit, setPendingSubmit] = useState<boolean | undefined>(
    false
  );
  const [awaitingAcqId, setawaitingAcqId] = useState<boolean>(true);
  const [awaitingConfigId, setawaitingConfigId] = useState<boolean>(true);
  const { systemId } = useParams();
  const delay = 2000;

  useEffect(() => {
    let id: any = undefined;
    if (
      acquisitionId &&
      !acquisitionStatus.startsWith("completed") &&
      !acquisitionStatus.startsWith("failed")
    ) {
      id = setInterval(checkAcquisitionStatus, delay);
    }
    return () => clearInterval(id);
  }, [acquisitionId, acquisitionStatus]);

  useEffect(() => {
    let id: any = undefined;
    if (
      surrogateId &&
      ["Checking", "in-progress", "pending"].indexOf(surrogateStatus) > -1
    ) {
      id = setInterval(checkSurrogateStatus, delay);
    }
    return () => clearInterval(id);
  }, [surrogateId, surrogateStatus]);

  async function addAuthorization() {
    if (isAuthenticated) {
      let token = await getAccessTokenSilently();
      if (token) {
        authorize(token);
      }
    }
  }

  const getSystemSummary = async () => {
    !get_auth_header() && (await addAuthorization());
    // Reload system if the cached system doesn't have any observations (e.g because it hasn't been updated)
    !system && (await getSystemDetails(systemId, true));
  };

  useEffect(() => {
    getSystemSummary();
  }, []);

  const getConfigurationId = async () => {
    console.log("In getConfigurationId systemId:", systemId);
    if (
      system &&
      system.id &&
      infoSourceId.length &&
      infoSourceId !== "Info source with a sampler is unavailable."
    ) {
      let configurationsResponse = await getConfigurations(system.id);
      console.log(
        "getConfigurations configurationsResponse:",
        configurationsResponse
      );
      let configuration = configurationsResponse?.[0];
      if (configuration) {
        setConfigurationId(configuration.id);
        setConfigurationStatus("completed");
        setBatchSize(configuration.model_training.batch_size);
        setEpochs(configuration.model_training.epochs);
        setInputIndependentValues(
          configuration.candidate_point_calculation.input_bounds
        );

        let observations = (system?.total_observations ?? 0).toString();
        console.log("system:", system);
      } else {
        setConfigurationStatus("No surrogate configuration found.");
        setAcquisitionStatus("No samples created.");
        setSurrogateStatus("No surrogate available.");
      }
      setawaitingConfigId(false);
    }
  };

  const getSurrogateId = async () => {
    console.log("In getSurrogateId systemId:", systemId, configurationId);
    if (system && system.id && configurationId) {
      let surrogatesResponse = await getSurrogates(system.id, configurationId);
      console.log("getSurrogates surrogatesResponse:", surrogatesResponse);
      console.log(
        "getSurrogates surrogatesResponse.length:",
        surrogatesResponse.length
      );
      if (surrogatesResponse && surrogatesResponse.length) {
        setSurrogateId(surrogatesResponse[0].id);
      } else {
        // Nothing to do
        setSurrogateStatus("No surrogate available.");
      }
    }
  };

  const getAcquisitionId = async () => {
    console.log(
      "In getAcquisitionId systemId:",
      systemId,
      " infoSourceId:",
      infoSourceId
    );
    if (systemId && infoSourceId) {
      let acquisitionIdResponse = await retrieveSampleAcquisitionId(
        systemId,
        infoSourceId
      );
      console.log("acquisitionIdResponse: ", acquisitionIdResponse);
      if (acquisitionIdResponse?.trim().length > 10) {
        setAcquisitionId(acquisitionIdResponse.trim());
      }
    }
    setawaitingAcqId(false);
  };

  const getInfoSourceId = async () => {
    console.log(
      "In getInfoSourceId systemId:",
      systemId,
      " infoSourceId:",
      infoSourceId
    );
    if (!infoSourceId && system && system.id) {
      console.log("system is set and infoSourceId is not set");
      if (system.info_sources && system.info_sources.length) {
        console.log("setting info source id");
        setInfoSourceId(system.info_sources[0].id);
      } else {
        console.log("getting info source");
        let sys_info = await getSystemDetails(systemId, true);
        console.log(sys_info);
        if (
          sys_info?.info_sources &&
          sys_info.info_sources.length &&
          sys_info.info_sources[0].id
        ) {
          setInfoSourceId(sys_info.info_sources[0].id);
        } else {
          setInfoSourceId("Info source with a sampler is unavailable.");
          setConfigurationStatus("Info source with a sampler is unavailable.");
          setAcquisitionStatus("Info source with a sampler is unavailable.");
          setSurrogateStatus("Info source with a sampler is unavailable.");
        }
      }
    }
  };

  const getParsedData = async () => {
    console.log(
      "In getParsedData systemId:",
      systemId,
      " infoSourceId:",
      infoSourceId
    );
    if (system && system.name) {
      console.log("getParsedData system.name:", system.name);
      let parsed_data = haveSeenThisFile({ name: system.name });
      console.log("getParsedData: parsed_data:", parsed_data);
      if (parsed_data) {
        setParsedData(parsed_data);
      }
    }
  };

  // Set defaults if available
  useEffect(() => {
    setInputVarRanges(system?.variables?.independent);
    getParsedData();
    getInfoSourceId();
  }, [system]);

  // Try to continue if a configuration already exists
  useEffect(() => {
    getConfigurationId();
    getAcquisitionId();
  }, [system, systemId, infoSourceId]);

  useEffect(() => {
    getSurrogateId();
  }, [systemId, configurationId]);

  const getDefaultsFromParsedData = async () => {
    console.log(
      "In getDefaultsFromParsedData system name:",
      system?.name,
      " parsedData: ",
      parsedData?.file_name
    );
    if (parsedData && parsedData.file_name) {
      let parsed_bounds: any = {};
      // Set the variable to optimize ("There can be only one.")
      // Constraint variables are stored separately, so this is the short list.
      if (parsedData.variables && parsedData.variables.inputs.length > 0) {
        // Note: We just switched bounds to dependent vars
        parsedData.variables.inputs.forEach((v: any) => {
          // default min,max to 0,1 if not define so we always end up with bounds defined
          // once user provided bounds is implemented, this defaulting may either be removed
          // or the user can override
          let min = v.lower_limit !== null ? v.lower_limit : 0;
          let max = v.upper_limit !== null ? v.upper_limit : 1;
          let unit = v.unit !== null ? v.unit : "";
          parsed_bounds = {
            ...parsed_bounds,
            [v.source_path]: {
              type: "explicitWithUnits",
              min: min,
              max: max,
              unit: unit,
            },
          };
        });
      } else {
        // Fall back to old parsing
        parsedData.selected_system.independent_variables.forEach((v: any) => {
          let min = v.min_value !== null ? v.min_value.value : 0;
          let max = v.max_value !== null ? v.max_value.value : 1;
          parsed_bounds = {
            ...parsed_bounds,
            [v.name]: { type: "explicit", min: min, max: max },
          };
        });
      }
      console.log("parsed_bounds (independents)", parsed_bounds);
      setInputIndependentValues(parsed_bounds);
    }
  };

  useEffect(() => {
    getDefaultsFromParsedData();
  }, [parsedData]);

  const requestSurrogateCreation = async () => {
    let surrogateResponse = await createSurrogate(systemId, configurationId);
    console.log("surrogateResponse", surrogateResponse);
    console.log("surrogateResponse.data", surrogateResponse.data);
    console.log("surrogateResponse.data.id", surrogateResponse.data.id);
    console.log(
      "surrogateResponse.data.creation_state",
      surrogateResponse.data.creation_state
    );
    if (
      surrogateResponse &&
      surrogateResponse.data &&
      surrogateResponse.data.id
    ) {
      setSurrogateId(surrogateResponse.data.id);
      setSurrogateStatus(surrogateResponse.data.creation_state);
    }
  };

  useEffect(() => {
    if (
      acquisitionId &&
      acquisitionStatus.startsWith("completed") &&
      !surrogateId
    ) {
      requestSurrogateCreation();
    }
  }, [acquisitionId, acquisitionStatus, surrogateId]);

  // TODO On load check if there is a configuration for this system already
  // TODO On load check if there is an acquisition for this system / info source already

  const validateForm = () => {
    let validForm = true;
    let validationStatus = { ...invalid };
    if (batchSize === "" || isNaN(parseFloat(batchSize))) {
      validationStatus = { ...validationStatus, batchSize: true };
      validForm = false;
    } else {
      validationStatus = { ...validationStatus, batchSize: false };
    }
    if (epochs === "" || isNaN(parseFloat(epochs))) {
      validationStatus = { ...validationStatus, epochs: true };
      validForm = false;
    } else {
      validationStatus = { ...validationStatus, epochs: false };
    }
    setInvalid({ ...validationStatus });
    return validForm;
  };

  const submitConfig = async (e: React.SyntheticEvent) => {
    setPendingSubmit(true);
    e.preventDefault();
    // valiadtion
    if (!validateForm()) {
      setPendingSubmit(false);
      return;
    }
    // format suitable for POST submission
    let independent_bounds = {};
    Object.keys(inputIndependentValues).forEach((key: any) => {
      let variable: any = inputIndependentValues[key];
      independent_bounds = {
        ...independent_bounds,
        [key]: {
          min: [Number(variable.min)],
          max: [Number(variable.max)],
          unit: String(variable.unit),
          type: "explicitWithUnits",
        },
      };
    });
    console.log("independent_bounds", independent_bounds);

    if (system && system?.info_sources && system?.info_sources?.length > 0) {
      // Create samples
      if (!acquisitionId) {
        let acquisitionResponse = await createAcquisition(
          systemId,
          infoSourceId,
          numberOfSamples?.value,
          independent_bounds
        );
        console.log("acquisitionResponse:", acquisitionResponse);
        console.log("acquisitionResponse.data:", acquisitionResponse.data);
        if (
          acquisitionResponse &&
          acquisitionResponse.data &&
          acquisitionResponse.data.id
        ) {
          console.log(
            "acquisitionResponse.data.id:",
            acquisitionResponse.data.id
          );
          setAcquisitionId(acquisitionResponse.data.id);
          setAcquisitionStatus(acquisitionResponse.data.status);
          storeSampleAcquisitionId(systemId, infoSourceId, acquisitionId);
        }
      }

      // Create config
      if (!configurationId) {
        let configurationResponse = await createConfig(
          systemId,
          "Config_" + system.name,
          batchSize,
          epochs,
          infoSourceId,
          independent_bounds
        );
        if (
          configurationResponse &&
          configurationResponse.data &&
          configurationResponse.data.id
        ) {
          setConfigurationId(configurationResponse.data.id);
          setConfigurationStatus("completed");
        }
      }
    } else {
      console.log("Info source with a sampler is unavailable.");
    }

    setPendingSubmit(false);
  };

  const checkAcquisitionStatus = async () => {
    console.log(
      `In checkAcqStatus systemId ${systemId} infosourceId: ${infoSourceId} acqid: ${acquisitionId} ${acquisitionStatus}`
    );
    if (
      infoSourceId &&
      acquisitionId &&
      !acquisitionStatus.startsWith("completed")
    ) {
      console.log("checkAcqStatus getAcq");
      let acquisitionResponse = await getAcquisition(
        systemId,
        infoSourceId,
        acquisitionId
      );
      console.log("acquisitionResponse", acquisitionResponse);
      console.log("acquisitionResponse.status", acquisitionResponse.status);
      if (acquisitionResponse && acquisitionResponse.status) {
        storeSampleAcquisitionId(systemId, infoSourceId, acquisitionId);
        setAcquisitionResponse(acquisitionResponse);
        // TODO proper integration !
        setNumberOfSamples({
          name: acquisitionResponse.progress.total_samples,
          value: acquisitionResponse.progress.total_samples,
        });
        setAcquisitionStatus(
          acquisitionResponse.status +
            // in progress status detail
            (acquisitionResponse.status === "in-progress"
              ? `: ${acquisitionResponse.progress.completed_samples}/${acquisitionResponse.progress.total_samples}`
              : "") +
            // completed status detail
            (acquisitionResponse.status === "completed"
              ? `: ${acquisitionResponse.progress.completed_samples}/${acquisitionResponse.progress.total_samples}`
              : "") +
            // failed status detail
            (acquisitionResponse.status === "failed"
              ? `: ${acquisitionResponse.error_message}`
              : "")
        );
      }
    }
  };

  const checkSurrogateStatus = async () => {
    console.log(
      "In checkSurrogateStatus systemId",
      systemId,
      acquisitionStatus,
      surrogateId,
      surrogateStatus
    );
    if (
      infoSourceId &&
      surrogateId &&
      ["Checking", "in-progress", "pending"].indexOf(surrogateStatus) > -1
    ) {
      console.log("checkSurrogateStatus getSurrogate");
      let surrogateResponse = await getSurrogate(
        systemId,
        configurationId,
        surrogateId
      );
      console.log("surrogateResponse", surrogateResponse);
      console.log(
        "surrogateResponse.creation_state",
        surrogateResponse.creation_state
      );
      if (
        surrogateResponse &&
        surrogateResponse.id &&
        surrogateResponse.creation_state
      ) {
        setSurrogateId(surrogateResponse.id);
        setSurrogateStatus(surrogateResponse.creation_state);
      }
    }
  };

  const cancelCurrentAcquisition = async () => {
    cancelAcquisition(systemId, infoSourceId, acquisitionId);
  };

  let enableSubmit =
    !awaitingAcqId &&
    !awaitingConfigId &&
    !pendingSubmit &&
    system &&
    system?.info_sources &&
    system?.info_sources?.length > 0 &&
    (!acquisitionId || !configurationId);

  let enableCancel =
    acquisitionResponseData && acquisitionResponseData.status === "in-progress";

  return isAuthenticated ? (
    <>
      <div className="md:flex md:items-center my-8 w-full text-center dark:text-white">
        <div className="md:w-8/12 lg:4/5">
          <h1 className="text-2xl font-bold leading-7 text-gray-700 dark:text-white ml-28 sm:truncate sm:text-3xl sm:tracking-tight">
            Surrogate Configuration
          </h1>
        </div>
      </div>

      <div className="md:flex md:items-center my-8 w-full text-center dark:text-white">
        <div className="md:w-3/12 lg:1/5">
          <label
            id="batch-size"
            className="block text-gray-700 text-md dark:text-white"
            htmlFor="batch-size"
          >
            Batch size
            <span title="The smallest training step you would like the surrogate to learn from.">
              <InformationCircleIcon className="h-5 w-5 inline ml-1" />
            </span>
          </label>
        </div>
        <div className="md:w-4/12 lg:3/5">
          <input
            id="batch-size-input"
            type="number"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none dark:bg-slbgray5d  focus:shadow-outline dark:text-white dark:text-white"
            value={batchSize}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setBatchSize(e.target.value);
            }}
          />
          {invalid.batchSize && (
            <div className="w-full text-left p-1 text-sm text-red-700">
              Please enter a value{" "}
            </div>
          )}
        </div>
      </div>
      <div className="md:flex md:items-center mb-8 w-full text-center dark:text-white">
        <div className="md:w-3/12 lg:1/5">
          <label
            id="epochs"
            className="block text-gray-700 text-md dark:text-white"
            htmlFor="epochs"
          >
            Epochs
            <span title="How many times should the the training data represent real world data.">
              <InformationCircleIcon className="h-5 w-5 inline ml-1" />
            </span>
          </label>
        </div>
        <div className="md:w-4/12 lg:3/5">
          <input
            id="epochs-input"
            type="number"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline dark:bg-slbgray5d  dark:text-white"
            value={epochs}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setEpochs(e.target.value);
            }}
          />
          {invalid.epochs && (
            <div className="w-full text-left p-1 text-sm text-red-700">
              Please enter a value{" "}
            </div>
          )}
        </div>
      </div>
      <div className="md:flex md:items-center mb-8 w-full text-center dark:text-white">
        <div className="md:w-3/12 lg:1/5">
          <label
            id="bounds-status"
            className="block text-gray-700 text-md dark:text-white"
            htmlFor="Bounds"
          >
            Bounds
            <span title="The minimum and maximum values that should be considered for generating samples.">
              <InformationCircleIcon className="h-5 w-5 inline ml-1" />
            </span>
          </label>
        </div>
        <div className="md:w-4/12 lg:3/5"></div>
      </div>
      <VariableRangeInput
        title=""
        tooltip=""
        variables={inputVarRanges}
        values={inputIndependentValues}
        setValues={setInputIndependentValues}
        switchDescription={true}
      ></VariableRangeInput>
      <div className="md:flex md:items-center mb-8 w-full text-center dark:text-white">
        <div className="md:w-3/12 lg:1/5">
          <label
            id="epochs"
            className="block text-gray-700 text-md dark:text-white"
            htmlFor="NumberOfSamples"
          >
            Number of Samples
            <span title="The number of samples to create and use for surrogate training.">
              <InformationCircleIcon className="h-5 w-5 inline ml-1" />
            </span>
          </label>
        </div>
        <div className="md:w-6/12 lg:3/5" id="sample-input-wrapper">
          <ComboBoxComponent
            onChange={(value: INameValue) => {
              setNumberOfSamples(value);
            }}
            value={numberOfSamples}
            options={numberOFSamplesOptions}
          ></ComboBoxComponent>
        </div>
      </div>

      <div className="md:flex md:items-center mb-8 w-full text-center dark:text-white">
        <div className="md:w-3/12 lg:1/5">
          <label
            id="configuration-status"
            className="block text-gray-700 text-md dark:text-white"
            htmlFor="ConfigurationCreationStatus"
          >
            Configuration Creation Status
            <span title="Surrogate Configuration Saved and Available">
              <InformationCircleIcon className="h-5 w-5 inline ml-1" />
            </span>
          </label>
        </div>
        <div className="md:w-4/12 lg:3/5">{configurationStatus}</div>
      </div>
      <div className="md:flex md:items-center mb-8 w-full text-center dark:text-white">
        <div className="md:w-3/12 lg:1/5">
          <label
            id="sample-status"
            className="block text-gray-700 text-md dark:text-white"
            htmlFor="SampleCreationStatus"
          >
            Sample Creation Status
            <span title="Samples Generated and Available">
              <InformationCircleIcon className="h-5 w-5 inline ml-1" />
            </span>
          </label>
        </div>
        <div className="md:w-4/12 lg:3/5">
          {acquisitionStatus}{" "}
          {acquisitionResponseData &&
            (acquisitionResponseData.status === "in-progress" ||
              acquisitionResponseData.status === "completed") && (
              <div className="ml-3">
                <ProgressBar
                  progress={acquisitionResponseData.progress.completed_samples}
                  total={acquisitionResponseData.progress.total_samples}
                />
              </div>
            )}{" "}
        </div>
        <button
          id="cancel-button"
          onClick={cancelCurrentAcquisition}
          className={`mx-10 bg-red-500 ${
            enableCancel
              ? "hover:bg-red-700 dark:hover:bg-red-900"
              : "bg-opacity-40"
          } dark:text-white py-2 px-4 font-bold rounded focus:outline-none focus:shadow-outline`}
          disabled={!enableCancel}
        >
          Cancel
        </button>
      </div>
      <div className="md:flex md:items-center mb-8 w-full text-center dark:text-white">
        <div className="md:w-3/12 lg:1/5">
          <label
            id="surrogate-status"
            className="block text-gray-700 text-md dark:text-white"
            htmlFor="SurrogateCreationStatus"
          >
            Surrogate Creation Status
            <span title="Surrogate Created and Available">
              <InformationCircleIcon className="h-5 w-5 inline ml-1" />
            </span>
          </label>
        </div>
        <div className="md:w-4/12 lg:3/5">{surrogateStatus}</div>
      </div>
      <div className=" md:items-top mb-8 w-full block">
        <div className="inline-block md:w-6/12 lg:6/12 text-right">
          <button
            id="submit-button"
            onClick={submitConfig}
            className={`bg-slbvoxblue ${
              enableSubmit
                ? "hover:bg-blue-700 dark:hover:bg-blue-900"
                : "bg-opacity-40"
            } dark:bg-slbvoxblued dark:text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}
            disabled={!enableSubmit}
          >
            Submit
          </button>
        </div>
        <div
          className="inline-block md:w-6/12 lg:6/12"
          id="surrogate-evaluation-wrapper"
        >
          <Link
            className={`ml-4 py-3 px-4 rounded border cursor-pointer bg-white text-black hover:bg-gray-300 hover:text-black`}
            to={`/surrogate-evaluation/${system?.id}`}
          >
            Surrogate Evaluation
          </Link>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};

export default SurrogateConfig;
