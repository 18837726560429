import AppSettings from "./AppSettings";
import { axiosInstance as axios } from "./axios";

export const addfile = async ({ data }: { data: any }) => {
  try {
    const url = `${AppSettings.endpoints.facadeUrl}/services/parse/`;
    const response = await axios.post(url, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const createSystem = async (data: any) => {
  try {
    const url = `${AppSettings.endpoints.facadeUrl}/systems/`;
    const response = await axios.post(url, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const createInfoSources = async (systemId: string, data: any) => {
  try {
    const url = `${AppSettings.endpoints.facadeUrl}/systems/${systemId}/info-sources/`;
    const response = await axios.post(url, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const createConfigurations = async (systemId: string, data: any) => {
  //todo: payload props
  try {
    const url = `${AppSettings.endpoints.facadeUrl}/systems/${systemId}/configurations/`;
    const response = await axios.post(url, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const createSampleAcquisition = async (
  systemId: string,
  infoSourceId: string,
  data: any
) => {
  //todo: payload props
  try {
    const url = `${AppSettings.endpoints.facadeUrl}/systems/${systemId}/info-sources/${infoSourceId}/observation_acquisitions`;
    const response = await axios.post(url, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const createSurrogateModel = async (
  systemId: string,
  configurationId: string
) => {
  try {
    const url = `${AppSettings.endpoints.facadeUrl}/systems/${systemId}/configurations/${configurationId}/surrogates/`;
    const response = await axios.post(url, {});
    return response;
  } catch (error) {
    return error;
  }
};

export const createOptimizationRequest = async (
  systemId: string,
  configurationId: string,
  surrogateId: string,
  payload: any
) => {
  //todo: payload props
  try {
    const url = `${AppSettings.endpoints.facadeUrl}/systems/${systemId}/configurations/${configurationId}/surrogates/${surrogateId}/multibranch_optimization`;
    const response = await axios.post(url, payload);
    return response;
  } catch (error) {
    return error;
  }
};

export const createVisualizationRequest = async (
  systemId: string,
  configurationId: string,
  surrogateId: string,
  payload: any
) => {
  //todo: payload props
  try {
    const url = `${AppSettings.endpoints.facadeUrl}/systems/${systemId}/configurations/${configurationId}/surrogates/${surrogateId}/visualization`;
    const { data } = await axios.post(url, payload, { responseType: "blob" });
    return data;
  } catch (error) {
    return error;
  }
};

export const fetchSystems = async (systemId?: string) => {
  try {
    let url = `${AppSettings.endpoints.facadeUrl}/systems/${systemId ?? ""}`;
    const response = await axios.get(url, {});
    return response?.data;
  } catch (error) {
    return error;
  }
};

export const fetchAcquisition = async (
  systemId: string,
  infoSourceId: string,
  acquisitionId: string
) => {
  try {
    let url = `${AppSettings.endpoints.facadeUrl}/systems/${systemId}/info-sources/${infoSourceId}/observation_acquisitions/${acquisitionId}`;
    const response = await axios.get(url, {});
    return response?.data;
  } catch (error) {
    return error;
  }
};

export const fetchLatestAcquisition = async (
  systemId: string,
  infoSourceId: string
) => {
  try {
    let url = `${AppSettings.endpoints.facadeUrl}/systems/${systemId}/info-sources/${infoSourceId}/observation_acquisitions/?limit=1`;
    const response = await axios.get(url, {});
    return response?.data;
  } catch (error) {
    return error;
  }
};

export const fetchConfigurations = async (systemId: string) => {
  try {
    let url = `${AppSettings.endpoints.facadeUrl}/systems/${systemId}/configurations/`;
    const response = await axios.get(url, {});
    return response?.data;
  } catch (error) {
    return error;
  }
};

export const fetchSurrogate = async (
  systemId: string,
  configurationId: string,
  surrogateId: string
) => {
  try {
    let url = `${AppSettings.endpoints.facadeUrl}/systems/${systemId}/configurations/${configurationId}/surrogates/${surrogateId}`;
    const response = await axios.get(url, {});
    return response?.data;
  } catch (error) {
    return error;
  }
};

export const fetchSurrogates = async (
  systemId: string,
  configurationId: string
) => {
  try {
    let url = `${AppSettings.endpoints.facadeUrl}/systems/${systemId}/configurations/${configurationId}/surrogates/`;
    const response = await axios.get(url, {});
    return response?.data;
  } catch (error) {
    return error;
  }
};

export const fetchOptimization = async (
  systemId: string,
  configurationId: string,
  surrogateId: string,
  optimizationId: string
) => {
  try {
    let url = `${AppSettings.endpoints.facadeUrl}/systems/${systemId}/configurations/${configurationId}/surrogates/${surrogateId}/operations/${optimizationId}`;
    const response = await axios.get(url, {});
    return response?.data;
  } catch (error) {
    return error;
  }
};

// Note: This returns all surrogate operations (deffered results / async ops)
export const fetchOptimizations = async (
  systemId: string,
  configurationId: string,
  surrogateId: string
) => {
  try {
    let url = `${AppSettings.endpoints.facadeUrl}/systems/${systemId}/configurations/${configurationId}/surrogates/${surrogateId}/operations/`;
    const response = await axios.get(url, {});
    return response?.data;
  } catch (error) {
    return error;
  }
};

export const fetchOnnx = async (
  systemId: string,
  configurationId: string,
  surrogateId: string
) => {
  try {
    let url = `${AppSettings.endpoints.facadeUrl}/systems/${systemId}/configurations/${configurationId}/surrogates/${surrogateId}/onnx`;
    const response = await axios.get(url, { responseType: "blob" });
    return response?.data;
  } catch (error) {
    return error;
  }
};

export const createBatchCandidatePoint = async (
  systemId: string,
  configurationId: string,
  surrogateId: string,
  payload: any
) => {
  try {
    let url = `${AppSettings.endpoints.facadeUrl}/systems/${systemId}/configurations/${configurationId}/surrogates/${surrogateId}/batch_candidate_point_async`;
    const response = await axios.post(url, payload);
    return response?.data;
  } catch (error) {
    return error;
  }
};

export const getCandidatePointSychronous = async (
  systemId: string,
  configurationId: string,
  surrogateId: string,
  payload: any
) => {
  try {
    let url = `${AppSettings.endpoints.facadeUrl}/systems/${systemId}/configurations/${configurationId}/surrogates/${surrogateId}/batch_candidate_point`;
    const response = await axios.post(url, payload);
    return response?.data;
  } catch (error) {
    return error;
  }
};

export const listObservations = async (
  systemId: string,
  infoSourceId: string
) => {
  try {
    let url = `${AppSettings.endpoints.facadeUrl}/systems/${systemId}/info-sources/${infoSourceId}/observations/export`;
    const response = await axios.get(url);
    return response?.data;
  } catch (error) {
    return error;
  }
};

export const cancelSampleAcquisition = async (
  systemId: string,
  infoSourceId: string,
  acquisitionId: string,
  payload: any
) => {
  try {
    let url = `${AppSettings.endpoints.facadeUrl}/systems/${systemId}/info-sources/${infoSourceId}/observation_acquisitions/${acquisitionId}/cancel`;
    const response = await axios.post(url, payload);
    return response?.data;
  } catch (error) {
    return error;
  }
};

export const cancelSurrogateOperation = async (
  systemId: string,
  configurationId: string,
  surrogateId: string,
  operationId: string,
  payload: any
) => {
  try {
    let url = `${AppSettings.endpoints.facadeUrl}/systems/${systemId}/configurations/${configurationId}/surrogates/${surrogateId}/operations/${operationId}/cancel`;
    const response = await axios.post(url, payload);
    return response?.data;
  } catch (error) {
    return error;
  }
};
