import { createContext, useState, useEffect } from "react";
import {
  addfile,
  createSystem,
  createInfoSources,
  fetchSystems,
  createConfigurations,
  createSampleAcquisition,
  createSurrogateModel,
  createOptimizationRequest,
  createVisualizationRequest,
  fetchAcquisition,
  fetchConfigurations,
  fetchSurrogate,
  fetchSurrogates,
  fetchOptimization,
  fetchOptimizations,
  fetchOnnx,
  createBatchCandidatePoint,
  getCandidatePointSychronous,
  listObservations,
  cancelSampleAcquisition,
  cancelSurrogateOperation,
  fetchLatestAcquisition,
} from "../apis";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  getCreateConfigurationRequest,
  getCreateInfoSourcesRequest,
  getCreateAcquisitionRequest,
  getCreateOptimizationRequest,
  getCreateVisualizationRequest,
} from "../helpers/util";
import { authorize, get_auth_header } from "../apis/axios";
import { ISystem } from "../types/System";
import { useOidc } from "../helpers/oidc";

export type HomeContextType = {
  uploadCaseFile: Function;
  haveSeenThisFile: Function;
  getSystemDetails: Function;
  mainLoader: boolean;
  symmetrySystems: any[];
  files: any[];
  selectedSystemId: string;
  system: ISystem | undefined;
  setSystem: Function;
  getSelectedSystem: Function;
  createConfig: Function;
  getConfigurations: Function;
  createAcquisition: Function;
  getAcquisition: Function;
  createSurrogate: Function;
  getSurrogate: Function;
  getSurrogates: Function;
  getOptimizations: Function;
  storeSampleAcquisitionId: Function;
  retrieveSampleAcquisitionId: Function;
  createOptimization: Function;
  getOptimization: Function;
  storeOptimizationId: Function;
  getOnnx: Function;
  createVisualization: Function;
  create: Function;
  createCandidatePointCalculation: Function;
  getCandidatePointCalculationSynchronous: Function;
  getObservations: Function;
  cancelAcquisition: Function;
  cancelOperation: Function;
};

export const HomeContext = createContext<HomeContextType>({
  mainLoader: false,
  selectedSystemId: "",
  symmetrySystems: [],
  files: [],
  uploadCaseFile: () => {},
  getSystemDetails: () => {},
  haveSeenThisFile: () => {},
  system: undefined,
  setSystem: () => {},
  getSelectedSystem: () => {},
  createConfig: () => {},
  getConfigurations: () => {},
  createAcquisition: () => {},
  getAcquisition: () => {},
  createSurrogate: () => {},
  getSurrogate: () => {},
  getSurrogates: () => {},
  getOptimizations: () => {},
  storeSampleAcquisitionId: () => {},
  retrieveSampleAcquisitionId: () => {},
  createOptimization: () => {},
  getOptimization: () => {},
  storeOptimizationId: () => {},
  getOnnx: () => {},
  createVisualization: () => {},
  create: () => {},
  createCandidatePointCalculation: () => {},
  getCandidatePointCalculationSynchronous: () => {},
  getObservations: () => {},
  cancelAcquisition: () => {},
  cancelOperation: () => {},
});

const HomeContextProvider = (props: any) => {
  const [files, setFiles] = useState<any[]>([]);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [systems, setSystems] = useState<any[]>([]);
  const [selectedSystemId, setSelectedSystemId] = useState<string>("");
  const [system, setSystem] = useState<ISystem | undefined>(undefined);
  const [symmetrySystems, setSymmetrySystems] = useState<any[]>([]);
  const [mainLoader, setMainLoader] = useState<boolean>(false);
  const navigate = useNavigate();
  const { isAuthenticated, getAccessTokenSilently } = useOidc();

  useEffect(() => {
    if (window.localStorage !== undefined) {
      try {
        const data = window.localStorage.getItem("files");
        data !== null && setFiles(JSON.parse(data));
        const systems = window.localStorage.getItem("systems");
        systems !== null && setSystems(JSON.parse(systems));
        const selectedSystemId =
          window.localStorage.getItem("selectedSystemId");
        selectedSystemId !== null && setSelectedSystemId(selectedSystemId);
      } catch (error) {
        console.error("e msg", error);
      }
    }
  }, []);

  useEffect(() => {
    isAuthenticated && fetchAllSystems();
  }, [isAuthenticated]);

  const fetchAllSystems = async () => {
    !get_auth_header() && (await addAuthorization());
    const allSystems = await fetchSystems();
    allSystems?.length && setSymmetrySystems(allSystems);
  };

  async function addAuthorization() {
    const token = await getAccessTokenSilently();
    token && authorize(token);
  }

  /*
  function procVariableName(v: any) {
    // Expand any "paths"
    if (v["custom_variable_type"] == "CompositionVariable") {
      v["name"] = v["composition_path"][0];
    } else {
      v["name"] = v["path"];
    }

    // Make sure "user_name" is available
    v["user_name"] = v["user_name"] ?? v["name"];
    console.log("v now");
    console.dir(v);
  }
  */

  const uploadCaseFile = async (file: any) => {
    console.log("upload file", file);
    console.log("upload filename", file.name);
    //from client storage
    setMainLoader(true);

    /* Change of plans; always server-side parse
    const fileInClient = haveSeenThisFile(file);
    if (fileInClient) {
      return fileInClient;
    }
    */

    //from server
    const formData = new FormData();
    formData.append("type", "symmetry");
    formData.append("file", file);
    const response: any = await addfile({ data: formData });
    if (response?.data) {
      toast.success("Successfully parsed!");
      let parsed_data = response.data;
      let selected_system = null;
      /**
       * Defined optimizations take precedence.
       */
      if (parsed_data.optimizers && parsed_data.optimizers.length) {
        selected_system = parsed_data.optimizers[0];
      } else if (parsed_data.case_studies && parsed_data.case_studies.length) {
        selected_system = parsed_data.case_studies[0];
      } else {
        selected_system = null;
      }
      console.log("uploadCaseFile initial selected_system");
      console.dir(selected_system);

      // With the new metadata we can skip post-processing the parsed data
      // old pseudo-code: for every variable run procVariableName();

      parsed_data.file_name = file.name;
      parsed_data.source = selected_system.source;
      parsed_data.selected_system = selected_system;
      console.log("Saving parsed content to files.");
      console.dir(parsed_data);
      setFiles([parsed_data, ...files]);
      console.log("Saving key files.");
      updateClientStorage({ key: "files", content: [...files, parsed_data] });
      setSelectedFile(file);
      navigate(`/system-create/${file.name}`);

      setMainLoader(false);
    } else {
      toast.error("Something went wrong in file upload!");
      setMainLoader(false);
    }
    return response;
  };

  // check file present in client
  const haveSeenThisFile = (uploadFile: any) => {
    const foundFile = files.find(
      (cachedFile) => cachedFile.file_name === uploadFile.name
    );

    return foundFile ?? null;
  };

  //  create system
  const create = async (data: any) => {
    const systemId = await addSystem(data?.name, data);
    console.log(
      "HomeContext create systemId",
      systemId,
      "selectedFile",
      selectedFile
    );
    if (systemId && selectedFile) {
      const createInfoSourceResponse: any = await addInfoSources(systemId);
      setMainLoader(false);
      createInfoSourceResponse?.data?.id &&
        navigate(`/system-summary/${systemId}`);
    }
  };

  const addSystem = async (name: string, data: any) => {
    setMainLoader(true);
    const systemCreateReq = data;
    const systemCreateResponse: any = await createSystem(systemCreateReq);
    if (systemCreateResponse?.data?.id) {
      setSystems([systemCreateResponse.data, ...systems]);
      updateClientStorage({
        key: "systems",
        content: [systemCreateResponse.data, ...systems],
      });
      toast.success("Successfully created system!");
      return systemCreateResponse?.data?.id;
    } else {
      toast.error("Something went wrong in create system!");
      setMainLoader(false);
    }
  };

  //info sources
  const addInfoSources = async (systemId: string) => {
    const infoSourceData = await getCreateInfoSourcesRequest(
      selectedFile,
      selectedFile.name
    );
    const infoSourceResponse: any = await createInfoSources(
      systemId,
      infoSourceData
    );
    console.log("infoSourceResponse", infoSourceResponse);
    if (infoSourceResponse?.data?.id) {
      toast.success("Successfully created info sources!");
    } else {
      toast.error("Something went wrong in adding info sources!");
    }
    return infoSourceResponse;
  };

  // create config
  const createConfig = async (
    systemId: string,
    name: string,
    batchSize: string,
    epochs: string,
    infoSourceId: string,
    independent_bounds: any
  ) => {
    setMainLoader(true);
    const createConfigurationData = getCreateConfigurationRequest(
      name,
      batchSize,
      epochs,
      infoSourceId,
      independent_bounds
    );
    console.log("createConfigurationData", createConfigurationData);
    const createConfigurationResponse: any = await createConfigurations(
      systemId,
      createConfigurationData
    );
    console.log("createConfigurationResponse", createConfigurationResponse);
    if (createConfigurationResponse?.data?.id) {
      toast.success("Successfully created configuration!");
    } else {
      toast.error("Something went wrong in adding configuration!");
    }
    return createConfigurationResponse;
  };

  // create samples
  const createAcquisition = async (
    systemId: string,
    infoSourceId: string,
    num_samples: string,
    bounds: string
  ) => {
    setMainLoader(true);
    const createAcquisitionData = getCreateAcquisitionRequest(
      "latin-hypercube",
      num_samples,
      bounds
    );
    const createAcquisitionResponse: any = await createSampleAcquisition(
      systemId,
      infoSourceId,
      createAcquisitionData
    );
    console.log("createAcquisitionResponse", createAcquisitionResponse);
    if (createAcquisitionResponse?.data?.id) {
      toast.success("Successfully requested sample creation!");
    } else {
      toast.error("Something went wrong in adding sample creation!");
    }
    return createAcquisitionResponse;
  };

  //surrogate
  const createSurrogate = async (systemId: string, configurationId: string) => {
    const surrogateResponse: any = await createSurrogateModel(
      systemId,
      configurationId
    );
    console.log("surrogateResponse", surrogateResponse);
    if (surrogateResponse?.data?.id) {
      toast.success("Successfully requested surrogate creation!");
    } else {
      toast.error("Something went wrong in surrogate creation request!");
    }
    return surrogateResponse;
  };

  //optimization
  const createOptimization = async (
    systemId: string,
    configurationId: string,
    surrogateId: string,
    branches: string,
    dependent_variable: string,
    optimization_direction: string,
    fixed_values: any,
    constrained_values: any,
    input_constrained_values: any
  ) => {
    setMainLoader(true);
    const createOptimizationData = getCreateOptimizationRequest(
      branches,
      dependent_variable,
      optimization_direction,
      fixed_values,
      constrained_values,
      input_constrained_values
    );
    const createOptimizationResponse: any = await createOptimizationRequest(
      systemId,
      configurationId,
      surrogateId,
      createOptimizationData
    );
    console.log("createOptimizationData", createOptimizationData);
    setMainLoader(false);
    if (createOptimizationResponse?.data?.id) {
      toast.success("Successfully requested optimization.");
    } else {
      toast.error("Something went wrong in adding optimization.");
    }
    return createOptimizationResponse;
  };

  // visualization
  const createVisualization = async (
    systemId: string,
    configurationId: string,
    surrogateId: string,
    visualization_type: string,
    variable_name: string,
    variable_description: string,
    width: number = 700,
    height: number = 500
  ) => {
    setMainLoader(true);
    const createVisualizationData = getCreateVisualizationRequest(
      visualization_type,
      variable_name,
      variable_description,
      width,
      height
    );
    const createVisualizationResponse: any = await createVisualizationRequest(
      systemId,
      configurationId,
      surrogateId,
      createVisualizationData
    );
    console.log("createVisualizationResponse", createVisualizationResponse);
    setMainLoader(false);
    if (createVisualizationResponse) {
      toast.success("Successfully requested visualization.");
    } else {
      toast.error("Something went wrong in creating visualization.");
    }
    return createVisualizationResponse;
  };

  //acquisition details
  const getAcquisition = async (
    systemId: string,
    infoSourceId: string,
    acquisitionId: string
  ) => {
    setMainLoader(true);

    const acquisitionResponse = await fetchAcquisition(
      systemId,
      infoSourceId,
      acquisitionId
    );
    console.log("acquisitionResponse", acquisitionResponse);
    setMainLoader(false);
    return acquisitionResponse;
  };

  //surrogate details
  const getSurrogate = async (
    systemId: string,
    configurationId: string,
    surrogateId: string
  ) => {
    setMainLoader(true);

    const surrogateResponse = await fetchSurrogate(
      systemId,
      configurationId,
      surrogateId
    );
    console.log("surrogateResponse", surrogateResponse);
    setMainLoader(false);
    return surrogateResponse;
  };

  //optimization details
  const getOptimization = async (
    systemId: string,
    configurationId: string,
    surrogateId: string,
    optimizationId: string,
    show_loading: boolean = false
  ) => {
    if (show_loading) {
      setMainLoader(true);
    }

    const optimizationResponse = await fetchOptimization(
      systemId,
      configurationId,
      surrogateId,
      optimizationId
    );
    console.log("optimizationResponse", optimizationResponse);
    if (show_loading) {
      setMainLoader(false);
    }
    return optimizationResponse;
  };

  //optimizations list (filters async deferred results)
  const getOptimizations = async (
    systemId: string,
    configurationId: string,
    surrogateId: string
  ) => {
    setMainLoader(true);

    const optimizationsResponse = await fetchOptimizations(
      systemId,
      configurationId,
      surrogateId
    );
    console.log("optimizationsResponse", optimizationsResponse);
    setMainLoader(false);
    // Filter out everything, but MBO
    return optimizationsResponse.filter(
      (r: any) => r.operation === "multibranch_optimization"
    );
  };

  //surrogates list
  const getSurrogates = async (systemId: string, configurationId: string) => {
    setMainLoader(true);

    const surrogatesResponse = await fetchSurrogates(systemId, configurationId);
    console.log("surrogatesResponse", surrogatesResponse);
    setMainLoader(false);
    return surrogatesResponse;
  };

  //configuration list
  const getConfigurations = async (systemId: string) => {
    setMainLoader(true);

    const configurationsResponse = await fetchConfigurations(systemId);
    console.log("configurationsResponse", configurationsResponse);
    setMainLoader(false);
    return configurationsResponse;
  };

  //system details
  const getSystemDetails = async (systemId: string, force: boolean = false) => {
    setMainLoader(true);
    const systemLocal = systems.find((system) => system.id === systemId);
    console.log("getSystemDetails systemLocal is", systemLocal);
    if (!force && systemLocal) {
      setMainLoader(false);
      setSystem(systemLocal);
      return systemLocal;
    }

    const remoteSystem = await fetchSystems(systemId);
    console.log("getSystemDetails remoteSystem is", remoteSystem);
    setSystem(remoteSystem);
    setMainLoader(false);
    return remoteSystem;
  };

  //onnx
  const getOnnx = async (
    systemId: string,
    configurationId: string,
    surrogateId: string
  ) => {
    setMainLoader(true);

    const onnxResponse = await fetchOnnx(
      systemId,
      configurationId,
      surrogateId
    );
    console.log("onnxResponse", onnxResponse);
    setMainLoader(false);
    return onnxResponse;
  };

  const storeSampleAcquisitionId = (
    systemId: string,
    infoSourceId: string,
    acquisitionId: string
  ) => {
    let key = systemId + infoSourceId;
    updateClientStorage({ key: key, content: acquisitionId });
  };

  const retrieveSampleAcquisitionId = async (
    systemId: string,
    infoSourceId: string
  ) => {
    let key = systemId + infoSourceId;
    let data = window.localStorage.getItem(key) || "";
    if (data) {
      return JSON.parse(data);
    }
    let acquisition_list = await fetchLatestAcquisition(systemId, infoSourceId);
    let acquisitionId = acquisition_list[0]?.id;
    if (acquisitionId) {
      storeSampleAcquisitionId(systemId, infoSourceId, acquisitionId);
    }
    return acquisitionId;
  };

  const storeOptimizationId = (
    systemId: string,
    surrogateId: string,
    optimizationId: string
  ) => {
    let key = systemId + surrogateId;
    updateClientStorage({ key: key, content: optimizationId });
  };

  const updateClientStorage = (data: any) => {
    window.localStorage.setItem(data.key, JSON.stringify(data.content));
  };

  // const saveSelectedSystem = (systemId: string) => {
  //   setSelectedSystemId(systemId);
  //   window.localStorage.setItem("system", systemId);
  // };

  const getSelectedSystem = () => {
    if (selectedSystemId) {
      return selectedSystemId;
    }
    return window.localStorage.getItem("system");
  };

  const createCandidatePointCalculation = async (
    systemId: string,
    configurationId: string,
    surrogateId: string,
    samples: number,
    variableName: string
  ) => {
    let cpcResponse = await createBatchCandidatePoint(
      systemId,
      configurationId,
      surrogateId,
      { num_samples: samples, variable: variableName }
    );
    console.log(cpcResponse);
    if (cpcResponse.id) {
      toast.success("Successfully requested CPC.");
    } else {
      toast.error("Failed to request CPC.");
    }
    return cpcResponse;
  };

  const getCandidatePointCalculationSynchronous = async (
    systemId: string,
    configurationId: string,
    surrogateId: string,
    variableName: string
  ) => {
    let cpcResponse = await getCandidatePointSychronous(
      systemId,
      configurationId,
      surrogateId,
      { variable: variableName }
    );
    if (cpcResponse.candidate_points) {
      toast.success("Successfully recieved CPC.");
    } else {
      toast.error("Failed to recieve CPC.");
    }
    return cpcResponse;
  };

  const getObservations = async (systemId: string, infoSourceId: string) => {
    let obsResponse = await listObservations(systemId, infoSourceId);
    if (obsResponse) {
      toast.success("Successfully recieved Observations.");
    } else {
      toast.error("Failed to recieve Observations.");
    }
    return obsResponse;
  };

  const cancelAcquisition = async (
    systemId: string,
    infoSourceId: string,
    acquisitionId: string
  ) => {
    let cancelResponse = await cancelSampleAcquisition(
      systemId,
      infoSourceId,
      acquisitionId,
      {}
    );
    if (cancelResponse) {
      toast.success("Successfully cancelled acquisition.");
    } else {
      toast.error("Failed to cancel acquisition.");
    }
    return cancelResponse;
  };

  const cancelOperation = async (
    systemId: string,
    configurationId: string,
    surrogateId: string,
    operationId: string
  ) => {
    let cancelResponse = await cancelSurrogateOperation(
      systemId,
      configurationId,
      surrogateId,
      operationId,
      {}
    );
    if (cancelResponse) {
      toast.success("Successfully cancelled operation.");
    } else {
      toast.error("Failed to cancel operation.");
    }
    return cancelResponse;
  };

  return (
    <HomeContext.Provider
      value={{
        uploadCaseFile,
        haveSeenThisFile,
        getSystemDetails,
        mainLoader,
        symmetrySystems,
        selectedSystemId,
        system,
        setSystem,
        getSelectedSystem,
        createConfig,
        getConfigurations,
        createAcquisition,
        getAcquisition,
        createSurrogate,
        getSurrogate,
        getSurrogates,
        getOptimizations,
        storeSampleAcquisitionId,
        retrieveSampleAcquisitionId,
        createOptimization,
        getOptimization,
        storeOptimizationId,
        getOnnx,
        files,
        createVisualization,
        create,
        createCandidatePointCalculation,
        getCandidatePointCalculationSynchronous,
        getObservations,
        cancelAcquisition,
        cancelOperation,
      }}
    >
      {props.children}
    </HomeContext.Provider>
  );
};

export default HomeContextProvider;
