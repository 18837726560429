import React from "react";
import { useOidc } from "../helpers/oidc";

const LogoutButton = () => {
  const { logout, user, userManager } = useOidc();
  const id_token = user?.id_token;

  return (
    <div
      className="dark:bg-gray-600 bg-gray-100 p-2 mt-1 uppercase font-semibold text-xs cursor-pointer"
      onClick={() => {
        sessionStorage.clear();
        logout({
          id_token_hint: id_token,
          extraQueryParams: {
            returnTo: window.location.origin,
            client_id: userManager.settings.client_id,
          },
        });
      }}
      role="presentation"
    >
      Log Out
    </div>
  );
};

export default LogoutButton;
