import { useRoutes } from "react-router-dom";

import Home from "../pages/Home";
import SurrogateConfig from "../pages/SurrogateConfig";
import Optimization from "../pages/Optimization";
import { withAuthenticationRequired } from "./oidc";
import System from "../pages/System";
import SurrogateEvaluation from "../pages/SurrogateEvaluation";
import OptimizationResult from "../pages/OptimizationResult";
import CandidatePointCalculation from "../pages/CandidatePointCalculation";

const ProtectedRoute = ({ component, ...args }: any) => {
  const Component = withAuthenticationRequired(component, args);
  return <Component />;
};

export default function Router() {
  return useRoutes([
    { path: "/", element: <ProtectedRoute component={Home} /> },
    { path: "/start", element: <ProtectedRoute component={Home} /> },
    {
      path: "/system-summary/:systemId",
      element: <ProtectedRoute component={System} />,
    },
    {
      path: "/system-create/:systemId",
      element: <ProtectedRoute component={System} />,
    },
    {
      path: "/surrogate-config/:systemId",
      element: <ProtectedRoute component={SurrogateConfig} />,
    },
    {
      path: "/surrogate-evaluation/:systemId",
      element: <ProtectedRoute component={SurrogateEvaluation} />,
    },
    {
      path: "/optimization/:systemId",
      element: <ProtectedRoute component={Optimization} />,
    },
    {
      path: "/optimization-result/:systemId",
      element: <ProtectedRoute component={OptimizationResult} />,
    },
    {
      path: "/candidate-point-calculation/:systemId",
      element: <ProtectedRoute component={CandidatePointCalculation} />,
    },
  ]);
}
