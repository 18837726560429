import { useEffect, useRef, useState } from "react";
import { Combobox } from "@headlessui/react";
import { INameValue } from "../types/System";
import { XCircleIcon } from "@heroicons/react/solid";

export interface IComboBoxComponent {
  id?: string;
  value: INameValue | undefined;
  onChange: (value: any) => void;
  options: INameValue[];
}

export default function ComboBoxComponent({
  value,
  onChange,
  options,
}: IComboBoxComponent) {
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const comboboxRef = useRef();

  // hide options on outside click
  useEffect(() => {
    document.addEventListener("click", handleDocClick);
    return () => document.removeEventListener("click", handleDocClick);

    function handleDocClick(e: any) {
      if (comboboxRef && comboboxRef.current) {
        const ref: any = comboboxRef.current;
        if (!ref.contains(e.target)) {
          setShowOptions(false);
        }
      }
    }
  }, []);

  // clear text box value
  const clearValue = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    onChange({
      name: "",
      value: "",
    });
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>, model: INameValue) => {
    e.preventDefault();
    e.stopPropagation();
    onChange(model);
    setShowOptions(false);
  };

  return (
    <div className="p-3 rounded" ref={comboboxRef as any}>
      <div className="relative  mt-1">
        <div className="relative  cursor-default overflow-hidden rounded-lg bg-white dark:bg-slbgray5d text-left shadow-md sm:text-sm">
          <input
            className="w-full rounded-lg border-2 border-white py-2 pl-3 pr-10 text-sm leading-5 text-gray-700 dark:bg-slbgray5d dark:text-white outline-none"
            onChange={(event) => {
              onChange({
                name: event.target.value,
                value: event.target.value,
              });
            }}
            value={value?.value || ""}
            onKeyDown={(event: React.KeyboardEvent<HTMLElement>) => {
              if (
                showOptions &&
                (event.key === "Enter" || event.key === "Tab")
              ) {
                event.preventDefault();
                event.stopPropagation();
                setShowOptions(false);
              }
            }}
            onClick={() => setShowOptions(!showOptions)}
          />
          <div className="absolute inset-y-0 right-0 flex items-center pr-2">
            <XCircleIcon
              className="w-5 h-5 cursor-pointer text-gray-400"
              aria-hidden="true"
              onClick={clearValue}
            />
          </div>
        </div>
        <div className="absolute w-full">
          {showOptions &&
            options?.map((model, idx) => (
              <div
                className="py-1 text-left bg-white text-black cursor-pointer hover:bg-gray-400"
                key={idx}
                role="none"
                onClick={(e) => handleClick(e, model)}
                onMouseOver={() => onChange(model)}
              >
                <span
                  className=" block px-4 py-2 text-sm"
                  role="menuitem"
                  tabIndex={-1}
                >
                  {model.name}
                </span>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
