import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import Router from "./helpers/Router";
import { BrowserRouter } from "react-router-dom";
import HomeContextProvider from "../src/contexts/HomeContext";
import { AuthProvider } from "oidc-react";
import { oidcConfig } from "./helpers/oidc";
import ScrollToTop from "./helpers/ScrollToTop";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <AuthProvider {...oidcConfig}>
        <HomeContextProvider>
          <Navbar />
          <div className="flex items-start bg-slbgray1 text-white ">
            <Sidebar />
            <div className="flex min-h-screen pb-4 w-full flex-col items-center text-slblightfontcolor bg-slbgray10 dark:bg-slbgray5d dark:text-white">
              <Router />
            </div>
          </div>
        </HomeContextProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
