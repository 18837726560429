import React from "react";
import { useOidc } from "../helpers/oidc";

const LoginButton = () => {
  const { loginWithRedirect } = useOidc();

  return (
    <p
      className="mt-1 uppercase font-semibold text-gray-700 text-xs cursor-pointer"
      onClick={() => loginWithRedirect()}
      role="presentation"
      id="login-button"
    >
      Log In
    </p>
  );
};

export default LoginButton;
