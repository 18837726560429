import React, { useState, useContext, useEffect } from "react";
import MainLoader from "../components/common/MainLoader";
import { HomeContext } from "../contexts/HomeContext";
import { useParams } from "react-router-dom";
import { authorize, get_auth_header } from "../apis/axios";
// import Subheading from '../../components/Subheading';
import KeyValueItem from "../components/KeyValueItem";
import { InformationCircleIcon } from "@heroicons/react/solid";
import { useOidc } from "../helpers/oidc";
import { XCircleIcon, PlusCircleIcon } from "@heroicons/react/solid";
import ConfirmationModal from "../components/ConfirmationModal";
import AddVariableForm from "../components/AddVariableForm";
import { getSystemFromSymmetry } from "../helpers/util";
import { IVariable } from "../types/System";
import { Link } from "react-router-dom";

const deleteConfirmationMessage = "Are you sure you want to delete ";

const System = () => {
  const { isAuthenticated, getAccessTokenSilently } = useOidc();
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [confirmMsg, setConfirmMsg] = useState<string>("");
  const [parsedVariables, setParsedVariables] = useState<any>({});
  const [parsedCaseStudies, setParsedCaseStudies] = useState<any>({});
  const [parsedOptimizers, setParsedOptimizers] = useState<any>({});
  const [parsedData, setParsedData] = useState<any>();
  const [hasIndependentBounds, setHasIndependentBounds] =
    useState<boolean>(false);
  const [hasDependentBounds, setHasDependentBounds] = useState<boolean>(false);
  const [variable, setVariable] = useState<any>({});
  const [variableType, setVariableType] = useState<"independent" | "dependent">(
    "independent"
  );
  const [openAddVariableModal, setOpenAddVariableModal] =
    useState<boolean>(false);
  const {
    getSystemDetails,
    mainLoader,
    system,
    setSystem,
    files,
    haveSeenThisFile,
    create,
    getObservations,
  } = useContext(HomeContext);
  const { systemId } = useParams();

  const isEditable = window.location.href.includes("/system-create/");
  const independentVars = system?.variables?.independent;
  const dependentVars = system?.variables?.dependent;
  console.log({ independentVars });
  console.log({ dependentVars });

  async function addAuthorization() {
    if (isAuthenticated) {
      let token = await getAccessTokenSilently();
      if (token) {
        authorize(token);
      }
    }
  }
  console.log({ system });
  const getSystemSummary = async () => {
    !get_auth_header() && (await addAuthorization());
    console.log("getSystemSummary systemId is", systemId);
    const tempSystemDetails = files.find((file) => file.file_name === systemId);
    console.log(
      "getSystemSummary lookup by systemId is",
      files,
      tempSystemDetails
    );

    isEditable &&
      tempSystemDetails &&
      setSystem(getSystemFromSymmetry(tempSystemDetails));

    const systemDetails =
      !isEditable && (await getSystemDetails(systemId, true));
    systemDetails && setSystem(systemDetails);
    console.log("getSystemSummary set systemDetails", systemDetails);
  };

  const getParsedData = async () => {
    console.log("In getParsedData systemId:", systemId);
    if (system && system.name) {
      console.log("getParsedData system.name:", system.name);
      let parsed_data = haveSeenThisFile({ name: system.name });
      console.log("getParsedData: parsed_data:", parsed_data);
      if (parsed_data) {
        setParsedData(parsed_data);
      }
    }
  };

  useEffect(() => {
    if (parsedData) {
      setParsedVariables(parsedData.variables);
      setParsedCaseStudies(parsedData.case_studies);
      setParsedOptimizers(parsedData.optimizers);

      parsedData.optimizers?.[0]?.independent_variables?.[0]?.max_value &&
        setHasIndependentBounds(true);
      parsedData.optimizers?.[0]?.constraint_variables?.[0]?.max_value &&
        setHasDependentBounds(true);
    }
  }, [parsedData]);

  useEffect(() => {
    getSystemSummary();
  }, []);

  // Get parsed data to show what's available
  useEffect(() => {
    getParsedData();
  }, [system]);

  // confirmation modal
  const confirmRemoveVariable = (v: any, type: string) => {
    setConfirmMsg(`${deleteConfirmationMessage} ${v.description}?`);
    setVariable({ ...v, type });
    setOpenConfirmModal(true);
  };

  const submitRemoveVariable = () => {
    setOpenConfirmModal(false);
    const varType: "independent" | "dependent" = variable?.type;
    const newTypeVariables =
      system && system.variables && varType && system.variables[varType];
    const filteredVars = newTypeVariables?.filter(
      (newVar: any) => newVar?.name !== variable.name
    );
    let newSystem = { ...system };
    newSystem.variables &&
      newSystem.variables[varType] &&
      filteredVars &&
      (newSystem.variables[varType] = filteredVars);
    setSystem(newSystem);
  };

  //Add variable
  const checkUniqueVariableName = (newv: IVariable) => {
    if (!system || !system.variables) {
      return true;
    }
    let indep_check = system?.variables.independent.filter(
      (v) => v.name === newv.name
    );
    let dep_check = system?.variables.dependent.filter(
      (v) => v.name === newv.name
    );
    console.log(indep_check);
    console.log(dep_check);
    if (indep_check.length > 0 || dep_check.length > 0) {
      return false;
    }
    return true;
  };
  const showAddVariableForm = (type: "independent" | "dependent") => {
    setVariableType(type);
    setOpenAddVariableModal(true);
  };
  const handleAdd = (v: any) => {
    console.log("variableType:", variableType);
    console.log("variable:", v);
    setOpenAddVariableModal(false);
    const varType: "independent" | "dependent" = variableType;
    const newTypeVariables =
      system && system.variables && varType && system.variables[varType];
    const existingVarCheck = newTypeVariables?.filter(
      (newVar: any) => newVar?.name === v.name
    );
    console.log("existingVarCheck", existingVarCheck?.length);
    if (existingVarCheck && existingVarCheck.length === 0) {
      let newSystem = { ...system };
      newSystem.variables &&
        newSystem.variables[varType] &&
        (newSystem.variables[varType] = [
          ...newSystem.variables[varType],
          { ...v },
        ]);
      console.log("Setting new system", newSystem);
      setSystem(newSystem);
    }
    setOpenAddVariableModal(false);
  };

  const fetchObservations = async () => {
    if (system && system.info_sources && system.info_sources.length) {
      let observationResponse = await getObservations(
        system.id,
        system.info_sources[0].id
      );
      console.log("observations:", observationResponse);
      let data = new Blob([observationResponse], { type: "text/csv" });
      let csvURL = window.URL.createObjectURL(data);
      window.open(csvURL);
      window.URL.revokeObjectURL(csvURL);
    }
  };

  // Create system
  const createSystem = () => {
    create(system);
  };

  let enableDownload =
    independentVars &&
    independentVars.length > 0 &&
    independentVars[0].name &&
    system?.observed_bounds &&
    system?.observed_bounds[independentVars[0].name];

  return isAuthenticated ? (
    <>
      {mainLoader && <MainLoader />}
      <div className="md:flex md:items-center my-8 w-full text-center dark:text-white">
        <div className="md:w-8/12 lg:4/5">
          <h1 className="text-2xl font-bold leading-7 text-gray-700 dark:text-white ml-28 sm:truncate sm:text-3xl sm:tracking-tight">
            System {isEditable ? "Create" : "Summary"}
          </h1>
        </div>
      </div>
      <div className="w-full text-left">
        <KeyValueItem
          label={"Name"}
          value={system?.name}
          isEditable={isEditable}
        />

        <KeyValueItem label={"Description"} value={system?.description} />

        <div className="md:flex md:items-top mb-6">
          <div className="md:w-2/12 lg:1/5 px-6">
            <label
              className="block text-md"
              htmlFor="info_sources"
              id="info_sources"
            >
              System Information Sources
              <span title="What types of information sources are available for this system?">
                <InformationCircleIcon className="h-5 w-5 inline ml-1" />
              </span>
            </label>
          </div>
          :
          <div className="md:w-10/12 lg:4/5">
            <ul className="px-3 ">
              {system?.info_sources?.map((source: any, idx: number) => (
                <li key={idx}>{source.name}</li>
              ))}
            </ul>
          </div>
        </div>

        <div className="md:flex md:items-top mb-6">
          <div className="md:w-2/12 lg:1/5 px-6">
            <label
              className="block text-md"
              htmlFor="info_sources"
              id="info_sources"
            >
              Observation Count:
              <span title="How many observations does the system have?">
                <InformationCircleIcon className="h-5 w-5 inline ml-1" />
              </span>
            </label>
          </div>
          :
          <div className="md:w-10/12 lg:4/5">
            <ul className="px-3 ">{system?.total_observations}</ul>
          </div>
        </div>

        <div className="md:flex md:items-top mb-6">
          <div className="md:w-2/12 lg:1/5 px-6">
            <label
              className="block text-md"
              htmlFor="info_sources"
              id="info_sources"
            >
              Automation Sources
              <span title="What types of surrogate creation and optimization are available for this system?">
                <InformationCircleIcon className="h-5 w-5 inline ml-1" />
              </span>
            </label>
          </div>
          :
          <div className="md:w-10/12 lg:4/5">
            <ul className="px-3 ">
              {parsedVariables?.inputs?.length
                ? "Parsed Variables Available"
                : "Not using parsed variables"}
            </ul>
            <ul className="px-3 ">
              {parsedCaseStudies?.length
                ? "Case Study Available"
                : "No Case Studies Available"}
            </ul>
            <ul className="px-3 ">
              {parsedOptimizers?.length
                ? "Optimizer Available"
                : "No Optimizers Available"}{" "}
              {parsedOptimizers?.length > 0 &&
                (hasIndependentBounds
                  ? "(with input bounds)"
                  : "(without input bounds)")}{" "}
              {parsedOptimizers?.length > 0 &&
                (hasDependentBounds
                  ? "(with output bounds)"
                  : "(without output bounds)")}
            </ul>
          </div>
        </div>
        <div className="mb-4 px-6">
          <label
            className="block text-md  mb-2"
            id="file_structure"
            htmlFor="file_structure"
          >
            Data file structure{}
            <span title="The system's independent and dependent variables as available in its data files.">
              <InformationCircleIcon className="h-5 w-5 inline ml-1" />
            </span>
          </label>
        </div>
        <div className="md:flex md:items-top mb-8 text-center">
          <div className="w-full px-5 md:w-3/6">
            <label className="block mb-1 text-lg" htmlFor="formGridCode_month">
              Independent Variables{" "}
              {isEditable && (
                <button
                  onClick={() => showAddVariableForm("independent")}
                  className="bg-slbvoxblue hover:bg-blue-700 dark:bg-slbvoxblued text-white font-bold text-sm py-2 px-4 mb-2 rounded-full"
                >
                  <PlusCircleIcon
                    className="w-5 h-5 cursor-pointer text-gray-400 inline"
                    aria-hidden="true"
                  />{" "}
                  Add
                </button>
              )}
            </label>
            <div className="w-full border bg-white dark:bg-gray-700  rounded px-3 py-2 outline-none h-auto">
              <ul className="text-left text-md">
                {independentVars?.length === 0 ? (
                  <li>No Independent Variables Configured</li>
                ) : (
                  independentVars?.map((v: any, idx: number) => {
                    return (
                      <li key={idx}>
                        {isEditable && (
                          <XCircleIcon
                            className="w-5 h-5 cursor-pointer text-gray-400 inline"
                            aria-hidden="true"
                            onClick={() =>
                              confirmRemoveVariable(v, "independent")
                            }
                          />
                        )}{" "}
                        <strong>{v.description}</strong>
                        <span title={v.name}>
                          <InformationCircleIcon className="h-5 w-5 inline ml-1" />
                        </span>
                        {!isEditable && (
                          <>
                            {" "}
                            observed range:{" "}
                            {system?.observed_bounds &&
                            system?.observed_bounds[v.name]
                              ? `[${system?.observed_bounds[v.name].min}, ${
                                  system?.observed_bounds[v.name].max
                                }]`
                              : "Unknown"}
                          </>
                        )}
                      </li>
                    );
                  })
                )}
              </ul>
            </div>
          </div>
          <div className="w-full px-5 md:w-3/6">
            <label className="block mb-1  text-lg" htmlFor="formGridCode_month">
              Dependent Variables{" "}
              {isEditable && (
                <button
                  onClick={() => showAddVariableForm("dependent")}
                  className="bg-slbvoxblue hover:bg-blue-700 dark:bg-slbvoxblued text-white text-sm font-bold py-2 px-4 mb-2 rounded-full"
                >
                  <PlusCircleIcon
                    className="w-5 h-5 cursor-pointer text-gray-400 inline"
                    aria-hidden="true"
                  />{" "}
                  Add
                </button>
              )}
            </label>
            <div className="w-full border bg-white dark:bg-gray-700 rounded px-3 py-2 outline-none h-auto min-h-32">
              <ul className="text-left text-md">
                {dependentVars?.length === 0 ? (
                  <li>No Dependent Variables Configured</li>
                ) : (
                  dependentVars?.map((v: any, idx: number) => (
                    <li key={idx}>
                      {isEditable && (
                        <XCircleIcon
                          className="w-5 h-5 cursor-pointer text-gray-400 inline"
                          aria-hidden="true"
                          onClick={() => confirmRemoveVariable(v, "dependent")}
                        />
                      )}{" "}
                      <strong>{v.description}</strong>
                      <span title={v.name}>
                        <InformationCircleIcon className="h-5 w-5 inline ml-1" />
                      </span>
                      {!isEditable && (
                        <>
                          {" "}
                          observed range:{" "}
                          {system?.observed_bounds &&
                          system?.observed_bounds[v.name]
                            ? `[${system?.observed_bounds[v.name].min}, ${
                                system?.observed_bounds[v.name].max
                              }]`
                            : "Unknown"}
                        </>
                      )}
                    </li>
                  ))
                )}
              </ul>
            </div>
          </div>
        </div>
        {isEditable && (
          <button
            id="submit-button"
            onClick={(e) => createSystem()}
            className={`bg-slbvoxblue hover:bg-blue-700 dark:bg-slbvoxblued text-white font-bold py-2 mr-4 px-4 float-right rounded focus:outline-none focus:shadow-outline`}
          >
            Create
          </button>
        )}
      </div>
      <ConfirmationModal
        title={`Confirm variable removal`}
        open={openConfirmModal}
        setOpen={setOpenConfirmModal}
        message={confirmMsg}
        handleOk={submitRemoveVariable}
      />
      <AddVariableForm
        open={openAddVariableModal}
        type={variableType}
        setOpen={setOpenAddVariableModal}
        handleAdd={handleAdd}
        validateVariable={checkUniqueVariableName}
      />
      {system &&
      system.info_sources &&
      system.info_sources.length &&
      !isEditable ? (
        <div className=" md:items-top mb-8 w-full block">
          <div className="inline-block md:w-6/12 lg:6/12 text-right">
            <button
              id="submit-button"
              onClick={fetchObservations}
              className={`bg-slbvoxblue ${
                enableDownload
                  ? "hover:bg-blue-700 dark:hover:bg-blue-900"
                  : "bg-opacity-40"
              } dark:text-white font-bold dark:bg-slbvoxblued dark:text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline`}
              disabled={!enableDownload}
            >
              Download Observations
            </button>
          </div>
          <div
            className="inline-block md:w-6/12 lg:6/12"
            id="surrogate-config-wrapper"
          >
            <Link
              className={`ml-4 py-3 px-4 rounded border cursor-pointer bg-white text-black hover:bg-gray-300 hover:text-black`}
              to={`/surrogate-config/${system?.id}`}
            >
              Surrogate Configuration
            </Link>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  ) : (
    <></>
  );
};

export default System;
